import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core';
import { BlocBuilder, Layout, BtnIcon, Btn, TextField } from 'components';

import { Dimens, Colors } from 'styles';
import intl from 'react-intl-universal';
import { HomeContext, HomeContextType } from 'pages';

import { IcYoutube, IcLine, IcFacebook, } from 'assets';

const Items = [
  { title: 'M', valueId: 'company_mobile_phone', },
  { title: 'T', valueId: 'company_tel_phone', },
  { title: 'E', valueId: 'company_email', },
  { title: 'W', valueId: 'company_website', },
];

const FormItems = [
  { key: 'name', id: 'id_tf_name', placeholderId: 'home_section_contact_form_name_hint', height: Dimens.textfield, multiline: false },
  { key: 'phone', id: 'id_tf_phone', placeholderId: 'home_section_contact_form_phone_hint', height: Dimens.textfield, multiline: false },
  { key: 'email', id: 'id_tf_email', placeholderId: 'home_section_contact_form_email_hint', height: Dimens.textfield, multiline: false },
  { key: 'subject', id: 'id_tf_subject', placeholderId: 'home_section_contact_form_subject_hint', height: Dimens.textfield, multiline: false },
  { key: 'content', id: 'id_tf_content', placeholderId: 'home_section_contact_form_content_hint', height: 'auto', multiline: true },
];

type ViewId = {
  tfName: string;
  tfPhone: string;
  tfEmail: string;
  tfSubject: string;
  tfContent: string;
  btnSend: string;
};

export const HomeContact: React.FC & {
  Id: ViewId;
} = () => {
  const smallScreen = !useMediaQuery('(min-width: 768px)');
  const css = useCss({ smallScreen });

  const bloc = useContext<HomeContextType>(HomeContext).bloc;

  /* --- functions --- */

  const btnClicked = (id: string) => {
    switch (id) {
      case HomeContact.Id.btnSend:
        break;
    }
  };

  const textChanged = (id: string, txt: string) => {
    switch (id) {
      case HomeContact.Id.tfName:
        bloc?.contactName.next(txt);
        break;
      case HomeContact.Id.tfPhone:
        bloc?.contactPhone.next(txt);
        break;
      case HomeContact.Id.tfEmail:
        bloc?.contactEmail.next(txt);
        break;
      case HomeContact.Id.tfSubject:
        bloc?.contactSubject.next(txt);
        break;
      case HomeContact.Id.tfContent:
        bloc?.contactContent.next(txt);
        break;
    }
  };

  /* --- views --- */

  if (!bloc) return null;

  const infoView = () => (
    <Layout>
      <Layout alignItems={smallScreen ? 'center' : 'flex-start'}>
        <span className={css.companyName}>
          {intl.get('company_name')}
        </span>
        <span className={css.companyNameEn}>
          {intl.get('company_name_en')}
        </span>
        <span className={css.companyItem}>
          {intl.get('company_address')}
        </span>
        <Layout alignItems='flex-start'>
          {Items.map((item) => {
            return (
              <Layout
                key={`company_info_${item.title}`}
                direction='row'
                alignItems='center'
                className={css.companyItem}>
                <span className={css.companyItemTitle}>{item.title}</span>
                <span className={css.companyItemVal}>
                  {intl.get(item.valueId)}
                </span>
              </Layout>
            );
          })}
        </Layout>
        <Layout
          direction='row'
          alignItems='center'
          className={css.socialIcons}>
          <BtnIcon
            width={Dimens.homeContactSocialIcon}
            height={Dimens.homeContactSocialIcon}
            icon={IcYoutube}
            color={Colors.homeSocialIcon}
            highlightColor={Colors.primary} />
          <BtnIcon
            width={Dimens.homeContactSocialIcon}
            height={Dimens.homeContactSocialIcon}
            icon={IcLine}
            color={Colors.homeSocialIcon}
            highlightColor={Colors.primary}
            className={css.iconLine} />
          <BtnIcon
            width={Dimens.homeContactSocialIcon}
            height={Dimens.homeContactSocialIcon}
            icon={IcFacebook}
            color={Colors.homeSocialIcon}
            highlightColor={Colors.primary}
            className={css.iconFb} />
        </Layout>
      </Layout>
    </Layout>
  );

  const messageView = () => (
    <Layout className={css.inputRoot}>
      <Layout
        alignItems='stretch'
        width={smallScreen ? '65vw' : '35vw'}>
        {FormItems.map((item, index) => {
          const stream = bloc.getContact(index);
          return (
            <BlocBuilder
              key={`home_contact_form_${item.key}`}
              initialValue={stream.getValue()}
              subject={stream}
              builder={snapshot => {
                return (
                  <TextField
                    height={item.height}
                    border
                    borderColor={Colors.homeContactBox}
                    multiline={item.multiline}
                    hint={intl.get(item.placeholderId)}
                    txt={snapshot.data}
                    className={css.input}
                    onTextChanged={(txt) => textChanged(item.id, txt)} />
                );
              }}
            />
          );
        })}
        <BlocBuilder
          initialValue={bloc.contactSendAvl.getValue()}
          subject={bloc.contactSendAvl}
          builder={snapshot => {
            return (
              <Btn
                disable={!snapshot.data}
                width='30%'
                color={Colors.accent}
                txtColor={Colors.textW}
                txt={intl.get('send')}
                className={css.btnSend}
                onClick={() => btnClicked(HomeContact.Id.btnSend)} />
            );
          }}
        />
      </Layout>
    </Layout>
  );

  if (smallScreen) {
    return (
      <Layout
        alignSelf='stretch'
        alignItems='center'
        className={css.root}>
        {infoView()}
        {messageView()}
      </Layout>
    );
  }
  return (
    <Layout
      direction='row'
      alignSelf='stretch'
      justifyContent='center'
      className={css.root}>
      {infoView()}
      {messageView()}
    </Layout>
  );
};

HomeContact.Id = {
  tfName: 'id_tf_name',
  tfPhone: 'id_tf_phone',
  tfEmail: 'id_tf_email',
  tfSubject: 'id_tf_subject',
  tfContent: 'id_tf_content',
  btnSend: 'id_btn_send',
};

type CssProps = {
  smallScreen: boolean;
};

const useCss = makeStyles<{}, CssProps>({
  root: {
    marginTop: Dimens.marginL,
  },
  companyName: {
    fontSize: '1.1rem',
    fontWeight: 500,
  },
  companyNameEn: {
    fontSize: props => props.smallScreen ? '0.9rem' : '1.1rem',
    fontWeight: 500,
  },
  companyItem: {
    marginTop: Dimens.marginXS,
  },
  companyItemTitle: {
    width: '20px',
  },
  companyItemVal: {
    fontWeight: 400,
    marginLeft: Dimens.marginXS,
  },
  socialIcons: {
    marginTop: Dimens.marginL,
  },
  socialIcon: {
    '&:hover': {
      color: Colors.primary,
    },
    width: Dimens.homeContactSocialIcon,
    height: Dimens.homeContactSocialIcon,
    cursor: 'pointer',
  },
  iconLine: {
    marginLeft: Dimens.marginL,
  },
  iconFb: {
    marginLeft: Dimens.marginL,
  },
  inputRoot: {
    marginTop: props => props.smallScreen ? Dimens.marginL : '0px',
    marginLeft: props => props.smallScreen ? '0px' : Dimens.marginL,
  },
  input: {
    marginBottom: Dimens.marginS,
  },
  btnSend: {
    alignSelf: 'flex-end',
  },
});