import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Layout } from 'components';

import { Dimens, Colors } from 'styles';

type Props = {
  imageUrl: string;
  imageAlt: string;
  title: string;
  subTitle?: string;
  breadCrumbs?: string[];
};

export const PageHeader: React.FC<Props> = ({
  imageUrl,
  imageAlt,
  title,
  subTitle,
  breadCrumbs,
}) => {
  const smallScreen = !useMediaQuery('(min-width: 768px)');
  const css = useCss({ smallScreen });

  const getBreadCrumbsComponent = () => {
    if (breadCrumbs === null || breadCrumbs === undefined) return null;
    const content = breadCrumbs.join(' > ');
    return <span className={css.breadCrumbs}>{content}</span>
  }

  return (
    <section className={css.root}>
      <Layout className={css.imgLayout}>
        <LazyLoadImage
          width='100%'
          height='100%'
          effect='blur'
          src={imageUrl}
          alt={imageAlt}
          style={{
            objectFit: 'cover',
            objectPosition: 'center',
          }} />
      </Layout>
      <h1 className={css.title}>{title}</h1>
      <span className={css.titleSub}>{subTitle}</span>
      {getBreadCrumbsComponent()}
    </section>
  );
};

type CssProps = {
  smallScreen: boolean;
}

const useCss = makeStyles<
  {},
  CssProps,
  'root' | 'imgLayout' | 'title' | 'titleSub' | 'breadCrumbs'
>({
  root: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: props => props.smallScreen ? Dimens.pageHeaderH_S : Dimens.pageHeaderH,
  },
  imgLayout: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  title: {
    fontSize: props => props.smallScreen ? Dimens.textPageTitle_RWD : Dimens.textPageTitle,
    fontWeight: 'normal',
    color: Colors.textW,
    zIndex: 1,
  },
  titleSub: {
    fontSize: props => props.smallScreen ? Dimens.textPageTitleSub_RWD : Dimens.textPageTitleSub,
    fontWeight: 'normal',
    color: Colors.textW,
    marginTop: props => props.smallScreen ? '0px' : Dimens.marginM,
    zIndex: 1,
  },
  breadCrumbs: {
    fontSize: props => props.smallScreen ? Dimens.textM : Dimens.textM,
    fontWeight: 'normal',
    color: Colors.textW,
    marginTop: props => props.smallScreen ? '0px' : Dimens.marginS,
    zIndex: 1,
  },
});
