import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { isMobile } from 'react-device-detect';

import ColorUtils from 'color';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  width?: string;
  height?: string;
  icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  color: string;
  highlightColor?: string;
};

export const BtnIcon: React.FC<Props> = ({
  className,
  style,
  width,
  height,
  icon,
  color,
  highlightColor,
}) => {
  const css = useCss({ width, height, icon, color, highlightColor });

  const [isHover, setHover] = useState<boolean>(false);
  const [isActive, setActive] = useState<boolean>(false);

  const Icon = icon;

  let hColor = ColorUtils(color).darken(0.3).hex();
  if (highlightColor) {
    hColor = highlightColor;
  }

  let c = color;
  if (isActive) {
    c = ColorUtils(hColor).darken(0.2).hex();
  } else if (isHover) {
    c = hColor;
  }

  if (isMobile) {
    return (
      <div
        className={`${css.root} ${className}`}
        style={style}
        onTouchStart={() => setActive(true)}
        onTouchCancel={() => setActive(false)}
        onTouchEnd={() => setActive(false)}>
        <Icon
          color={c}
          className={css.icon} />
      </div>
    );
  } else {
    return (
      <div
        className={`${css.root} ${className}`}
        style={style}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onMouseDown={() => setActive(true)}
        onMouseOut={() => setActive(false)}
        onMouseUp={() => setActive(false)}>
        <Icon
          color={c}
          className={css.icon} />
      </div>
    );
  }
};

const useCss = makeStyles<{}, Props>({
  root: {
    display: 'flex',
    boxSizing: 'border-box',
    alignItems: 'center',
    cursor: 'pointer',
  },
  icon: {
    width: props => props.width,
    height: props => props.height,
  },
});