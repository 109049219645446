import React from 'react';
import clsx from 'clsx';
import { InputBase } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Layout } from 'component/common/layout';

import { Dimens, Colors } from 'styles';

import { SvgIconComponent } from '@material-ui/icons';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  // ui styles
  width?: string;
  height?: string;
  icon?: SvgIconComponent;
  iconColor?: string;
  border?: boolean;
  borderColor?: string;
  borderRadius?: string;
  backgroundColor?: string;
  fontColor?: string;
  cursor?: 'pointer';
  multiline?: boolean;
  // content
  readonly?: boolean;
  inputType?: 'text' | 'tel' | 'password' | 'email';
  hint?: string;
  txt?: string;
  onTextChanged?: (txt: string) => void;
}

export type TextFieldRef = HTMLDivElement;

export const TextField = React.forwardRef<TextFieldRef, Props>(({
  className,
  style,
  // ui style
  width = '100%',
  height = Dimens.textfield,
  icon,
  iconColor = Colors.iconB,
  border = false,
  borderColor = Colors.divider,
  borderRadius = '5px',
  backgroundColor = '#ffffff',
  fontColor = Colors.textB,
  cursor,
  multiline = false,
  // content
  readonly = false,
  inputType = 'text',
  hint,
  txt = '',
  onTextChanged,
}, ref) => {
  const css = useCss({ width, height, icon, iconColor, border, borderColor, borderRadius, backgroundColor, fontColor, cursor, multiline });
  const Icon = icon;
  return (
    <Layout
      ref={ref}
      className={`${css.root} ${className}`}
      style={style}>
      <InputBase
        multiline={multiline}
        rows={8}
        readOnly={readonly}
        type={inputType}
        placeholder={hint}
        value={txt}
        inputProps={{ style: { cursor: cursor } }}
        className={clsx(css.input)}
        onChange={event => {
          if (onTextChanged) {
            onTextChanged(event.target.value);
          }
        }} />
      {
        Icon ? (
          <Layout
            justifyContent='center'
            alignItems='center'
            className={css.icon}>
            <Icon
              style={{
                width: '100%',
                height: '100%',
                color: iconColor,
              }} />
          </Layout>
        ) : null
      }
    </Layout>
  );
});

const useCss = makeStyles<{}, Props>({
  root: {
    position: 'relative',
    width: props => props.width,
    height: props => props.height,
  },
  input: {
    height: props => props.height,
    alignSelf: 'stretch',
    fontSize: Dimens.textM,
    color: props => props.fontColor,
    backgroundColor: props => props.backgroundColor,
    cursor: props => props.cursor,
    borderRadius: (props) => props.borderRadius ?? '5px',
    borderStyle: props => props.border ? 'solid' : undefined,
    borderWidth: props => props.border ? Dimens.divider : undefined,
    borderColor: props => props.border ? props.borderColor : undefined,
    paddingLeft: Dimens.marginM,
    paddingRight: props =>
      props.icon ? `calc(${props.height} * 0.7 + ${Dimens.marginS} * 2)` : `${Dimens.marginM}px`,
    paddingTop: props => props.multiline ? `calc(${Dimens.marginS} + ${Dimens.marginXS})` : undefined,
  },
  icon: {
    position: 'absolute',
    width: props => `calc(${props.height} * 0.7)`,
    height: props => `calc(${props.height} * 0.7)`,
    top: '50%',
    right: Dimens.marginS,
    transform: 'translate(0%, -50%)',
  },
});
