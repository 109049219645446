import * as React from "react"

export const IcFacebook = (props: React.SVGProps<SVGSVGElement>) => {

  const color = props.color ? props.color : '#4c4c4c';

  return (
    <svg viewBox="0 0 67.43 67.43" {...props}>
      <rect fill={color} width={67.43} height={67.43} rx={33.71} ry={33.71} />
      <path
        d="M46.26 67.43V41.54H55l1.31-10.09h-10V25c0-2.92.82-4.91 5-4.91h5.35v-9a72.23 72.23 0 00-7.79-.4c-7.7 0-13 4.71-13 13.34v7.44h-8.74v10.07h8.72v25.89z"
        fill="#fff"
      />
    </svg>
  )
};