import React, { MouseEvent } from 'react';
import { makeStyles } from '@material-ui/styles';

type Props = {
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  itemScope?: boolean;
  itemType?: string;
  itemProp?: string;
  id?: string;
  width?: string;
  height?: string;
  flex?: number;
  direction?: 'column' | 'column-reverse' | 'row' | 'row-reverse';
  alignSelf?: 'center' | 'flex-start' | 'flex-end' | 'stretch';
  alignItems?: 'center' | 'flex-start' | 'flex-end' | 'stretch';
  justifyContent?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around';
  onClick?: (event: MouseEvent<HTMLDivElement>) => void;
  onHover?: (hover: boolean, event: MouseEvent<HTMLDivElement>) => void;
};

export type LayoutRef = HTMLDivElement;

export const Layout = React.forwardRef<LayoutRef, Props>(({
  children,
  className,
  style,
  itemScope,
  itemType,
  itemProp,
  id,
  width = 'auto',
  height = 'auto',
  flex,
  direction = 'column',
  alignSelf,
  alignItems = 'flex-start',
  justifyContent = 'flex-start',
  onClick,
  onHover,
}, ref) => {
  const css = useCss({ width, height, flex, direction, alignSelf, alignItems, justifyContent });

  return (
    <div
      itemScope={itemScope}
      itemType={itemType}
      itemProp={itemProp}
      ref={ref}
      id={id}
      className={`${css.root} ${className}`}
      style={style}
      onClick={(event) => {
        if (onClick) {
          onClick(event);
        }
      }}
      onMouseEnter={(event) => {
        if (onHover) {
          onHover(true, event);
        }
      }}
      onMouseLeave={(event) => {
        if (onHover) {
          onHover(false, event);
        }
      }}
    >
      {children}
    </div>
  );
});

const useCss = makeStyles<{}, Props>({
  root: {
    display: 'flex',
    boxSizing: 'border-box',
    flex: props => props.flex,
    width: props => props.width,
    height: props => props.height,
    flexDirection: props => props.direction,
    alignSelf: props => props.alignSelf,
    alignItems: props => props.alignItems,
    justifyContent: props => props.justifyContent,
  },
});
