import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';

import { Dimens, Colors } from 'styles';

export const News01Page: React.FC = () => {
  const css = useCss();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className={css.root}>
      <h2 className={css.title}>中華宗教藝術人文協會准予籌組</h2>
      <span className={css.date}>108.07.09</span>
      <div className={css.divider} />
      <div className={css.content}>
        <span>准予籌組<br /></span>
      </div>
    </section>
  );
};

const useCss = makeStyles({
  root: {
    padding: '40px 15vw 100px',
  },
  title: {
    alignSelf: 'flex-start',
    fontFamily: "'Noto Serif TC', serif",
    fontSize: Dimens.newsTextTitle,
    fontWeight: 'bold',
  },
  date: {
    marginTop: Dimens.marginS,
    marginBottom: Dimens.marginS,
    fontSize: Dimens.textM,
  },
  divider: {
    height: Dimens.divider,
    backgroundColor: Colors.divider,
  },
  content: {
    padding: '40px 0px'
  }
});