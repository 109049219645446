import intl from 'react-intl-universal';

export class Menu {
  static readonly About = new Menu('about', '/menu', 'menu_about');
  static readonly News = new Menu('news', '/news', 'menu_news');
  static readonly Religion = new Menu('religion', '/religion', 'menu_religion');

  static readonly ArtIntro = new Menu('art-intro', '/art/intro', 'menu_art_intro');
  static readonly ArtArtist = new Menu('artist', '/art/artist', 'menu_art_artist');
  // static readonly ArtArtworks = new Menu('artworks', '/art/artworks', 'menu_art_artworks');
  static readonly Art = new Menu('art', '/art', 'menu_art', true, [
    Menu.ArtIntro,
    Menu.ArtArtist,
    // Menu.ArtArtworks,
  ]);
  static readonly Humanities = new Menu('humanities', '/humanities', 'menu_humanities');
  static readonly Reports = new Menu('reports', '/reports', 'menu_reports');
  static readonly Contact = new Menu('contact', '/contact', 'menu_contact');

  static readonly array = [
    Menu.About,
    Menu.News,
    Menu.Religion,
    Menu.Art,
    Menu.Humanities,
    Menu.Reports,
    Menu.Contact,
  ];

  static readonly size = Menu.array.length;

  static parse(path: string): Menu | undefined {
    const menu = this.array.find((item) => {
      return item.path === path;
    });
    return menu;
  }

  private _key: string;
  private _path: string;
  private _nameId: string;
  private _more: boolean;
  private _subList: Menu[] | undefined;

  constructor(key: string, path: string, nameId: string,
    more: boolean = false, subList?: Menu[]) {
    this._key = key;
    this._path = path;
    this._nameId = nameId;
    this._more = more;
    this._subList = subList;
  }

  get key() { return this._key; }

  get path() { return this._path; }

  get nameId() { return this._nameId; }

  get name() { return intl.get(this._nameId); }

  get more() { return this._more; }

  get subList() { return this._subList; }

  equal(other: Menu | undefined): boolean {
    if (other === undefined) { return false; }
    return other.path === this._path;
  }
}