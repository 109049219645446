import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Layout, PageHeader, PageIntro, Footer } from 'components';

import { Dimens } from 'styles';
import intl from 'react-intl-universal';
import { basicAssetBucketHost } from 'constant';

const ImageBanner = `${basicAssetBucketHost}/arts/arts_banner.jpg`;
const Image01 = `${basicAssetBucketHost}/arts/arts_01.jpg`;
const Image02 = `${basicAssetBucketHost}/arts/arts_02.jpg`;
const IntroTitle = '藝術';
const IntroDesc = `
如何讓宗教信仰與藝術、生活結合?<br>
生活與宗教緊密結合<br>
宗教的教化和藝術的心靈感受，同樣都帶給人一種寄託感，
而如果能確實讓「宗教信仰藝術化」相信將能使只懂藝術的人們也可透
過欣賞藝術品，感受到其中所傳達的宗教意涵：
只懂宗教的人們，也可因欣賞與自身貼近的主題藝術,有更深刻的體悟。<br>
畢竟，宗教的感受往往難以言喻，透過畫家的畫筆，無需隻字片語，
賞畫者即可從畫作靜靜感受當中的意境。<br>
宗教與藝術的巧妙結合著實不易，值得讚許，
畢竟祂(它)們都應該要貼近人心、使融入生活之中，
使人們生活更加美好、真實。<br>
『一家一幅真跡畫』理念，以更具主題性、
時代性的主題讓畫作深植人心，使生活融入藝術之中，
使藝術更生活化，透過藝術家的觀察和彩繪，
各地的山、水、風、情得以被描繪，傳達出在地多元文化，
宗教文化中的精神感受及各種情感皆躍於書布，
延續著宗教、藝術、人文融合的美好。`;

const Detail01Title = '藝術委員會';
const Detail01Desc = `
主委 巫永堅 博士<br>
巫永堅老師簡介
`;
const Detail02Title = '曾/現任';
const Detail02Desc = `
臺北身心障礙服務推展協會/美術顧問<br>
中華美術設計協會/理事/臺灣<br>
中華平面設計協會/理事/臺灣<br>
臺灣海報設計協會/理事/臺灣<br>
中國流行色協會/理事/北京<br>
國立科技大學/任教/臺北<br>
國立藝術大學/任教/臺北<br>
上海大學 數碼藝術學院/任教/上海<br>
上海師範大學 美術學院/任教/上海<br>
上海常春藤童装/美術顧問/上海<br>
上海滿地企業形象策劃/資深顧問/上海<br>
廣碩國際貿易/董事/寧波<br>
上海永堅企業形象策劃/總經理/上海<br>
上海隱秀文化傳播/總經理/上海
`;
const Detail03Title = '設計作品';
const Detail03Desc = `
2010年中國上海世界博覽會吉祥物原創設計/上海<br>
2009年臺北聽障奧林匹克運動會吉祥物設計/臺灣<br>
臺北國際青年商會鼠年造型/臺北<br>
職棒俊國熊隊俊國熊造型/台中<br>
寶島歌王葉啟田競選立委造型/臺北<br>
飛龍文具PENTEL龍造型/臺北<br>
六福村主題樂園哈比哈妮造型/新竹<br>
遠雄集團Rich Bear造型/臺北<br>
亞洲棒球賽吉祥物造型/臺北<br>
NO AIDS 保險套寶寶造型/臺北<br>
特奧會特奧龍造型/臺北<br>
常春藤童裝WADA系列造型/上海
`;

type Props = {
  appbarHeight: string;
};

export const ArtPage: React.FC<Props> = ({
  appbarHeight,
}) => {
  const smallScreen = !useMediaQuery('(min-width: 768px)');
  const css = useCss({ appbarHeight, smallScreen });

  /* --- lifecycle --- */

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  /* --- views --- */

  const detailView = () => (
    <Layout
      alignSelf='center'
      direction='row'
      width={smallScreen ? '90%' : '65%'}
      className={css.content}>
      <Layout
        flex={1}>
        <h2
          style={{
            alignSelf: smallScreen ? 'center' : 'flex-start',
          }}
          className={css.title}
        >
          {Detail01Title}
        </h2>
        <span
          style={{ textAlign: smallScreen ? 'center' : undefined }}
          className={css.desc}
          dangerouslySetInnerHTML={{ __html: Detail01Desc }}
        />
        <h2
          className={css.title}
          style={{
            marginTop: `calc(${Dimens.marginL} * ${smallScreen ? 2 : 3})`
          }}
        >
          {Detail02Title}
        </h2>
        <span className={css.desc} dangerouslySetInnerHTML={{ __html: Detail02Desc }} />
        <h2
          className={css.title}
          style={{
            marginTop: `calc(${Dimens.marginL} * ${smallScreen ? 2 : 3})`
          }}
        >
          {Detail03Title}
        </h2>
        <span className={css.desc} dangerouslySetInnerHTML={{ __html: Detail03Desc }} />
      </Layout>
      {smallScreen ? null : (
        <Layout flex={1}>
          <LazyLoadImage
            width='100%'
            effect='blur'
            src={Image01}
            alt={`巫永堅老師`}
            style={{
              objectFit: 'cover',
              objectPosition: 'center',
            }} />
          <LazyLoadImage
            width='100%'
            effect='blur'
            src={Image02}
            alt={`巫永堅老師`}
            style={{
              objectFit: 'cover',
              objectPosition: 'center',
              marginTop: `calc(${Dimens.marginL} * 3)`,
            }} />
        </Layout>
      )}
    </Layout>
  );

  return (
    <>
      <main className={css.root}>
        <PageHeader
          imageUrl={ImageBanner}
          imageAlt='arts_banner'
          title={intl.get('art_section_header_title')}
          subTitle={intl.get('art_section_header_title_sub')}
        />
        <PageIntro
          title={IntroTitle}
          desc={IntroDesc}
        />
        {detailView()}
      </main>
      <footer>
        <Footer />
      </footer>
    </>
  );
};

type CssProps = {
  appbarHeight: string;
  smallScreen: boolean;
}

const useCss = makeStyles<
  {},
  CssProps,
  'root' | 'content' | 'title' | 'desc'
>({
  root: {
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    alignItems: 'stretch',
    marginTop: props => props.appbarHeight,
    minHeight: props => `calc(100vh - ${props.appbarHeight} - ${Dimens.footer})`,
  },
  content: {
    padding: props => props.smallScreen ? `0px ${Dimens.marginM} ${Dimens.marginL} 0px` : `calc(${Dimens.marginL} * 2) 0px`,
  },
  title: {
    alignSelf: 'flex-start',
    fontFamily: "'Noto Serif TC', serif",
    fontSize: props => props.smallScreen ? Dimens.textL_RWD : Dimens.textL,
    fontWeight: 'bold',
    letterSpacing: '3px',
  },
  desc: {
    alignSelf: 'stretch',
    marginTop: Dimens.marginM,
  },
});
