export const Dimens = {
  marginXS: '5px',
  marginS: '10px',
  marginM: '20px',
  marginL: '40px',

  corner: '5px',

  textXS: '0.75rem',
  textS: '0.85rem',
  textM: '0.95rem',
  textL: '2.0rem',
  textL_RWD: '1.6rem',
  textXL: '2.7rem',
  textXL_RWD: '2.0rem',
  textPageTitle: '3.5rem',
  textPageTitle_RWD: '2.7rem',
  textPageTitleSub: '1.6rem',
  textPageTitleSub_RWD: '1.0rem',

  divider: '1px',
  dividerL: '2px',

  appbar: '65px',
  appbarS: '50px',
  appbarMenuSub: '40px',
  appbarMenuMoreIcon: '20px',
  appbarIcon: '30px',
  drawerW: '200px',
  footer: '100px',
  pageHeaderH: '300px',
  pageHeaderH_S: '180px',
  btn: '45px',
  textfield: '45px',

  /* --- home page --- */
  homeAboutTitle_RWD: '1.7rem',
  homeContactSocialIcon: '50px',
  homeContactFormContent: '135px',

  /* --- about page --- */
  aboutHeaderH: '300px',
  aboutHeaderH_S: '180px',
  aboutIdealImageH: '300px',
  aboutHopeImageH: '300px',

  /* --- humanities page --- */

  /* --- news page --- */
  newsCardTitle: '1.7rem',
  newsCardTitle_RWD: '1.3rem', 
  newsCardDay: '4rem',
  newsCardDay_RWD: '2.5rem',
  newsCardYearMonth: '1.3rem',
  newsCardYearMonth_RWD: '1rem',
  newsTextTitle: '1.3rem',

  /* --- reports page --- */
  reportsCardIndicator: '4px',
  reportsCardTitle: '1.7rem',
  reportsCardTitle_RWD: '1.3rem', 
  reportsCardContentH: '80px'
};
