import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core';
import { Layout, PageHeader, PageIntro, Footer } from 'components';

import { Dimens } from 'styles';
import { basicAssetBucketHost } from 'constant';
import intl from 'react-intl-universal';

const ImageBanner = `${basicAssetBucketHost}/religion/religion_banner.jpg`;
const IntroTitle = '宗教';
const IntroDesc = `
我們將全世界的各大宗教，不分彼此，
懷抱著尊重包容與謙卑理解的心境，透過畫家的心和手，
將宗教的精神、教義、故事、活動、代表人物、圖騰等等、
用藝術的創作，融入生活的品味，行銷於市場，
讓承購者至少擁有一幅(或以上)能傳承百世，
又有意義的作品，更為兼顧人文教義及資產累積的多重效能。
`;
const Detail01Title = '宗教委員會  主委 李麗鳳 老師';

type Props = {
  appbarHeight: string;
};

export const ReligionPage: React.FC<Props> = ({
  appbarHeight,
}) => {
  const smallScreen = !useMediaQuery('(min-width: 768px)');
  const css = useCss({ appbarHeight, smallScreen });

  /* --- lifecycle --- */

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  /* --- views --- */

  return (
    <>
      <main className={css.root}>
        <PageHeader
          imageUrl={ImageBanner}
          imageAlt='humanities_banner'
          title={intl.get('religion_section_header_title')}
          subTitle={intl.get('religion_section_header_title_sub')}
        />
        <PageIntro
          title={IntroTitle}
          desc={IntroDesc}
        />
        <Layout
          alignSelf='center'
          direction='row'
          width={smallScreen ? '90%' : '65%'}
          className={css.content}>
          <span>{Detail01Title}</span>
        </Layout>
      </main>
      <footer>
        <Footer />
      </footer>
    </>
  );
};

type CssProps = {
  appbarHeight: string;
  smallScreen: boolean;
}

const useCss = makeStyles<
  {},
  CssProps,
  'root' | 'content'
>({
  root: {
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    alignItems: 'stretch',
    marginTop: props => props.appbarHeight,
    minHeight: props => `calc(100vh - ${props.appbarHeight} - ${Dimens.footer})`,
  },
  content: {
    paddingBottom: `calc(${Dimens.marginL} * 2)`,
  },
});
