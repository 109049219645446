import React, { useState } from 'react';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { Paper, ButtonBase, Popper, ClickAwayListener } from '@material-ui/core';
import { Layout } from 'components';

import ColorUtils from 'color';
import { Menu } from 'models';

import { Dimens, Colors } from 'styles';

import { ExpandMoreRounded } from '@material-ui/icons';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  menu: Menu;
  subMenu?: Menu[];
  onClick?: (menu: Menu) => void;
}

export const AppbarMenu: React.FC<Props> = ({
  className,
  style,
  menu,
  subMenu,
  onClick,
}) => {
  const css = useCss();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const open = Boolean(anchorEl);

  return (
    <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
      <Layout
        direction='row'
        justifyContent='center'
        alignItems='center'
        className={clsx(css.root, className)}
        style={style}
        onHover={(hover, event) => {
          setAnchorEl(hover ? event.currentTarget : null);
        }}
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
          if (onClick) {
            onClick(menu);
          }
        }}>
        <AButton
          className={css.btn}>
          {menu.name}
        </AButton>
        {!menu.more ? null : (
          <ExpandMoreRounded className={css.icon} />
        )}
        {!menu.more || !subMenu ? null : (
          <Popper
            open={open}
            anchorEl={anchorEl}
            className={css.dropdownRoot}>
            <Paper
              elevation={4}
              className={css.dropdown}>
              {subMenu.map((item) => {
                return (
                  <ButtonBase
                    key={`sub_menu_${item.key}`}
                    className={css.dropdownBtn}
                    onClick={() => {
                      if (onClick) {
                        onClick(item);
                      }
                    }}>
                    {item.name}
                  </ButtonBase>
                );
              })}
            </Paper>
          </Popper>
        )}
      </Layout>
    </ClickAwayListener>
  );
};

const useCss = makeStyles({
  root: {
    position: 'relative',
    '&:active': {
      '& $icon': {
        color: ColorUtils(Colors.primary).darken(0.2).hex(),
      },
      '& $btn': {
        color: ColorUtils(Colors.primary).darken(0.2).hex(),
      },
    },
    '&:hover': {
      '& $icon': {
        color: Colors.primary,
      },
      '& $btn': {
        color: Colors.primary,
      },
    },
    outline: 'none',
    cursor: 'pointer',
  },
  btn: {},
  icon: {
    color: Colors.iconB,
    width: Dimens.appbarMenuMoreIcon,
    height: Dimens.appbarMenuMoreIcon,
  },
  dropdownRoot: {
    zIndex: 1200,
  },
  dropdown: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    width: '150px',
    backgroundColor: '#ffffff',
    marginTop: Dimens.marginS,
  },
  dropdownBtn: {
    '&:hover': {
      color: Colors.primary,
    },
    height: Dimens.appbarMenuSub,
    fontSize: Dimens.textS,
    color: Colors.textB,
  }
});

type ButtonProps = {};

const AButton = styled.a<ButtonProps>`
    /* :active {
      color: ${ColorUtils(Colors.primary).darken(0.2).hex()} !important;
    }; */
    outline: none;
    border: none;
    font-size: ${Dimens.textM};
    color: ${Colors.textB};
    background-color: transparent;
    padding: 0px;
    -webkit-tap-highlight-color: transparent;
    user-select: none;
`;