import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core';
import { Layout, BtnText } from 'components';

import { Dimens, Colors } from 'styles';
import { basicAssetBucketHost } from 'constant';
import intl from 'react-intl-universal';

const image = `${basicAssetBucketHost}/home/about/bg_home_about.jpg`;

type Props = {};

export const HomeAbout: React.FC<Props> = () => {
  const smallScreen = !useMediaQuery('(min-width: 768px)');

  const css = useCss({ smallScreen });

  return (
    <Layout
      alignItems='center'
      className={css.root}>
      <h1 className={css.title}>{intl.get('home_section_about_title')}</h1>
      <span className={css.desc}>{intl.get('home_section_about_desc')}</span>
      <BtnText
        elementType='a'
        txt={intl.get('home_section_about_more')}
        color='#ffffff'
        className={css.btnMore} />
    </Layout>
  );
};

type CssProps = {
  smallScreen: boolean;
}

const useCss = makeStyles<
  {},
  CssProps,
  'root' | 'imgBg' | 'infoRoot' | 'title' | 'desc' | 'btnMore'
>({
  root: {
    backgroundImage: `url("${image}")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
  imgBg: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    objectFit: 'cover',
    objectPosition: 'center',
  },
  infoRoot: {
    position: 'absolute',
    top: '0px',
    left: '0px',
  },
  title: {
    fontFamily: "'Noto Serif TC', serif",
    fontSize: props => props.smallScreen ? Dimens.homeAboutTitle_RWD : Dimens.textXL,
    fontWeight: 'bold',
    color: Colors.textW,
    letterSpacing: '3px',
    marginTop: props => `calc(${Dimens.marginL} * ${props.smallScreen ? 2 : 3})`,
  },
  desc: {
    alignSelf: 'center',
    width: props => props.smallScreen ? '85%' : '67%',
    color: Colors.textW,
    marginTop: Dimens.marginL,
  },
  btnMore: {
    margin: props => `calc(${Dimens.marginL} * ${props.smallScreen ? 2 : 3}) 0px`,
    zIndex: 2,
  },
})