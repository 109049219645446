import { basicAssetBucketHost } from "constant";
import { Painting } from "../painting";

export class Paintings {
  static 巫永堅 = [
    new Painting('0001', '0', '高屏地區-大尖山夕陽', `${basicAssetBucketHost}/artist/%E5%B7%AB%E6%B0%B8%E5%A0%85/arts/%E9%AB%98%E5%B1%8F4716786843613.jpg`),
    new Painting('0002', '0', '高屏地區-月世界', `${basicAssetBucketHost}/artist/%E5%B7%AB%E6%B0%B8%E5%A0%85/arts/%E9%AB%98%E5%B1%8F4716786843637.jpg`),
    new Painting('0003', '0', '高屏-4716786843644', `${basicAssetBucketHost}/artist/%E5%B7%AB%E6%B0%B8%E5%A0%85/arts/%E9%AB%98%E5%B1%8F4716786843644.jpg`),
    new Painting('0004', '0', '高屏-柴山海景', `${basicAssetBucketHost}/artist/%E5%B7%AB%E6%B0%B8%E5%A0%85/arts/%E9%AB%98%E5%B1%8F4716786843651.jpg`),
    new Painting('0005', '0', '高屏地區-山地門', `${basicAssetBucketHost}/artist/%E5%B7%AB%E6%B0%B8%E5%A0%85/arts/%E9%AB%98%E5%B1%8F4716786843620.jpg`),
    new Painting('0006', '0', '高屏-旗津', `${basicAssetBucketHost}/artist/%E5%B7%AB%E6%B0%B8%E5%A0%85/arts/%E9%AB%98%E5%B1%8F4716786843668.jpg`),
    new Painting('0007', '0', '花蓮-4716786846355', `${basicAssetBucketHost}/artist/%E5%B7%AB%E6%B0%B8%E5%A0%85/arts/%E8%8A%B1%E8%93%AE4716786846355.jpg`),
    new Painting('0008', '0', '花蓮-4716786846362', `${basicAssetBucketHost}/artist/%E5%B7%AB%E6%B0%B8%E5%A0%85/arts/%E8%8A%B1%E8%93%AE4716786846362.jpg`),
    new Painting('0009', '0', '花蓮-4716786846379', `${basicAssetBucketHost}/artist/%E5%B7%AB%E6%B0%B8%E5%A0%85/arts/%E8%8A%B1%E8%93%AE4716786846379.jpg`),
  ];

  static all = [
    ...Paintings.巫永堅,
  ];
}