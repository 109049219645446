import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core';
import { SizeMe } from 'react-sizeme';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Layout } from 'components';

import { Dimens, Colors } from 'styles';
import { basicAssetBucketHost } from 'constant';

const Images = [
  {
    url: `${basicAssetBucketHost}/home/artworks/4716786842050.jpg`,
    author: '巫永堅',
    name: '美國印記',
  },
  {
    url: `${basicAssetBucketHost}/home/artworks/4716786845334.jpg`,
    author: '巫永堅',
    name: '台北大同',
  },
  {
    url: `${basicAssetBucketHost}/home/artworks/4716786846270.jpg`,
    author: '人小二',
    name: '鹿港車站',
  },
  {
    url: `${basicAssetBucketHost}/home/artworks/4716786833027.jpg`,
    author: '丁禹仲',
    name: '台北大同',
  },
];

type Props = {};

export const HomeArtworks: React.FC<Props> = () => {
  const screenL = useMediaQuery('(min-width: 1000px)');
  const screenM = useMediaQuery('(min-width: 768px)');

  const css = useCss();
  const scss = useSmallCss();

  /* --- views --- */

  const image = (index: number) => {
    const image = Images[index];
    const smallScreen = !screenM && !screenL;
    return (
      <>
        <LazyLoadImage
          itemProp='image'
          width='100%'
          height='100%'
          effect='blur'
          src={image.url}
          alt={`home_artworks_${index + 1}`}
          style={{
            objectFit: 'cover',
            objectPosition: 'center',
          }} />
        <Layout
          justifyContent='flex-end'
          className={css.itemCover}>
          <span
            itemProp='author'
            className={smallScreen ? scss.itemAuthor : css.itemAuthor}>
            {image.author}
          </span>
          <span
            itemProp='name'
            className={smallScreen ? scss.itemName : css.itemName}>
            {image.name}
          </span>
        </Layout>
      </>
    );
  };

  if (!screenL && !screenM) {
    return (
      <SizeMe>
        {({ size }) => {
          const height = `${(size.width ?? 1440) * 0.56}px`;
          return (
            <Layout
              alignItems='stretch'>
              {Images.map((_, index) => {
                return (
                  <Layout
                    key={`image_${index}`}
                    width='100%'
                    height={height}
                    className={scss.item}>
                    {image(index)}
                  </Layout>
                );
              })}
            </Layout>
          );
        }}
      </SizeMe>
    );
  }
  return (
    <SizeMe>
      {({ size }) => {
        console.log('size', size);
        const height = `${(size.width ?? 952) * 0.41}px`;
        return (
          <Layout
            width={screenL ? '65%' : '95%'}
            alignSelf='center'
            alignItems='stretch'
            className={css.root}>
            <Layout direction='row'>
              <Layout
                itemScope itemType='http://schema.org/Painting'
                height={height}
                className={css.itemSmall}>
                {image(0)}
              </Layout>
              <Layout
                itemScope itemType='http://schema.org/Painting'
                height={height}
                className={css.itemLarge}>
                {image(1)}
              </Layout>
            </Layout>
            <Layout direction='row'>
              <Layout
                itemScope itemType='http://schema.org/Painting'
                height={height}
                className={css.itemLarge}>
                {image(2)}
              </Layout>
              <Layout
                itemScope itemType='http://schema.org/Painting'
                height={height}
                className={css.itemSmall}>
                {image(3)}
              </Layout>
            </Layout>
          </Layout>
        );
      }}
    </SizeMe>
  );
};

const useCss = makeStyles({
  root: {
    marginTop: Dimens.marginL,
  },
  itemSmall: {
    position: 'relative',
    flex: 4.1,
    margin: `${Dimens.marginS} ${Dimens.marginS}`,
  },
  itemLarge: {
    position: 'relative',
    flex: 5.9,
    margin: `${Dimens.marginS} ${Dimens.marginS}`,
  },
  itemCover: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '100%',
    backgroundImage: 'linear-gradient(transparent, transparent, #000000c0)',
    padding: `${Dimens.marginM} ${Dimens.marginM}`,
  },
  itemAuthor: {
    fontSize: '2.0rem',
    color: Colors.textW,
  },
  itemName: {
    fontSize: Dimens.textM,
    fontWeight: 500,
    color: Colors.textW,
    marginTop: Dimens.marginS,
  },
});

const useSmallCss = makeStyles({
  item: {
    position: 'relative',
    marginTop: Dimens.marginM,
  },
  itemAuthor: {
    fontSize: '1.7rem',
    color: Colors.textW,
  },
  itemName: {
    fontSize: Dimens.textM,
    fontWeight: 500,
    color: Colors.textW,
    marginTop: Dimens.marginXS,
  },
});