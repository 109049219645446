import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { Dimens, Colors } from 'styles';
import { basicAssetBucketHost } from 'constant';

export const News02Page: React.FC = () => {
  const less1000 = !useMediaQuery('(min-width: 1000px)');
  const css = useCss();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className={css.root}>
      <h2 className={css.title}>發起人會議、第一次籌備會議議</h2>
      <span className={css.date}>108.08.15</span>
      <div className={css.divider} />
      <div className={css.content}>
        <span>
          發起人會議、第一次籌備會議議<br />
          決議：<br />
            1.林璿筑<br />
            2.李沛林<br />
            3.張志源<br />
            4.楊湘婷<br />
            5.李麗端<br />
            6.簡淑麗<br />
            7.李文正<br />
          等7人擔任籌備委員。<br />
        </span>
        <LazyLoadImage
          width={less1000 ? '100%' : '80%'}
          effect='blur'
          src={`${basicAssetBucketHost}/news/photo_1080815_01.jpg`}
          alt='photo_1080815_01.png'
          style={{
            marginTop: '40px',
          }}
        />
        <span>發起人會議合照</span>
        <LazyLoadImage
          width={less1000 ? '100%' : '80%'}
          effect='blur'
          src={`${basicAssetBucketHost}/news/photo_1080815_02.jpg`}
          alt='photo_1080815_02.jpg'
          style={{
            marginTop: '40px',
          }}
        />
        <span>籌備委員</span>
      </div>
    </section >
  );
};

const useCss = makeStyles({
  root: {
    padding: '40px 15vw 100px',
  },
  title: {
    alignSelf: 'flex-start',
    fontFamily: "'Noto Serif TC', serif",
    fontSize: Dimens.newsTextTitle,
    fontWeight: 'bold',
  },
  date: {
    marginTop: Dimens.marginS,
    marginBottom: Dimens.marginS,
    fontSize: Dimens.textM,
  },
  divider: {
    height: Dimens.divider,
    backgroundColor: Colors.divider,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    padding: '40px 0px'
  }
});