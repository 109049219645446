import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';

import { Dimens, Colors } from 'styles';

export const News05Page: React.FC = () => {
  const css = useCss();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className={css.root}>
      <h2 className={css.title}>成立大會報請主管機關</h2>
      <span className={css.date}>108.10.28</span>
      <div className={css.divider} />
      <div className={css.content}>
        <span>
          成立大會報請主管機關<br />
        </span>
      </div>
    </section >
  );
};

const useCss = makeStyles({
  root: {
    padding: '40px 15vw 100px',
  },
  title: {
    alignSelf: 'flex-start',
    fontFamily: "'Noto Serif TC', serif",
    fontSize: Dimens.newsTextTitle,
    fontWeight: 'bold',
  },
  date: {
    marginTop: Dimens.marginS,
    marginBottom: Dimens.marginS,
    fontSize: Dimens.textM,
  },
  divider: {
    height: Dimens.divider,
    backgroundColor: Colors.divider,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    padding: '40px 0px'
  }
});