const Month = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export class News {
  private _title: string;
  private _content: string;
  private _imageUrl: string;
  private _date: string;

  constructor(title: string, content: string,
    imageUrl: string, date: string) {
    this._title = title;
    this._content = content;
    this._imageUrl = imageUrl;
    this._date = date;
  }

  get title() { return this._title; }

  get content() { return this._content; }

  get imageUrl() { return this._imageUrl; }

  get date() { return this._date; }

  get day() {
    try {
      const arr = this._date.split('/');
      return arr[2];
    } catch (e) {
      return '';
    }
  }

  get yearMonth() {
    try {
      const arr: string[] = this._date.split('/');
      return Month[parseInt(arr[1]) - 1] + ' ' + arr[1];
    } catch (e) {
      return '';
    }
  }
}