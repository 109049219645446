export class Report {
  private _title: string;
  private _content: string;
  private _imageUrl: string;
  private _date: string;

  constructor(title: string, content: string,
    imageUrl: string, date: string) {
    this._title = title;
    this._content = content;
    this._imageUrl = imageUrl;
    this._date = date;
  }

  get title() { return this._title; }

  get content() { return this._content; }

  get imageUrl() { return this._imageUrl; }

  get date() { return this._date; }
}