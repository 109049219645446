import { Subscription, BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

export class HomeBloc {
  private _disposables: Subscription;

  private _contactName: BehaviorSubject<string>;
  private _contactPhone: BehaviorSubject<string>;
  private _contactEmail: BehaviorSubject<string>;
  private _contactSubject: BehaviorSubject<string>;
  private _contactContent: BehaviorSubject<string>;
  private _contactSendAvl: BehaviorSubject<boolean>;

  constructor() {
    this._disposables = new Subscription();

    this._contactName = new BehaviorSubject<string>('');
    this._contactPhone = new BehaviorSubject<string>('');
    this._contactEmail = new BehaviorSubject<string>('');
    this._contactSubject = new BehaviorSubject<string>('');
    this._contactContent = new BehaviorSubject<string>('');
    this._contactSendAvl = new BehaviorSubject<boolean>(false);

    this.setContactSendAvlObserver();
  }

  get contactName() { return this._contactName; }
  get contactPhone() { return this._contactPhone; }
  get contactEmail() { return this._contactEmail; }
  get contactSubject() { return this._contactSubject; }
  get contactContent() { return this._contactContent; }
  get contactSendAvl() { return this._contactSendAvl; }

  dispose(): void {
    this._disposables.unsubscribe();
  }

  /* --- functions --- */

  getContact(index: number): BehaviorSubject<string> {
    switch (index) {
      case 1:
        return this._contactPhone;
      case 2:
        return this._contactEmail;
      case 3:
        return this._contactSubject;
      case 4:
        return this._contactContent;
      default:
        return this._contactName;
    }
  }

  setContactSendAvlObserver(): void {
    const it = combineLatest(
      this._contactName.pipe(
        map(name => name ? true : false),
      ),
      this._contactPhone.pipe(
        map(phone => phone ? true : false),
      ),
      this._contactEmail.pipe(
        map(email => email ? true : false)
      ),
      this._contactSubject.pipe(
        map(subject => subject ? true : false),
      ),
      this._contactContent.pipe(
        map(content => content ? true : false),
      )
    ).subscribe({
      next: avls => {
        this.contactSendAvl.next(
          avls[0] && avls[1] && avls[2] && avls[3] && avls[4]
        );
      }
    });
    this._disposables.add(it);
  }
}
