import { Subscription, BehaviorSubject } from 'rxjs';

export class AppBloc {
  private _disposables: Subscription;

  private _showDrawer: BehaviorSubject<boolean>;

  constructor() {
    this._disposables = new Subscription();

    this._showDrawer = new BehaviorSubject<boolean>(false);
  }

  get showDrawer() { return this._showDrawer; }

  dispose(): void {
    this._disposables.unsubscribe();
  }

  /* --- functions --- */

}