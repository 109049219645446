import { basicAssetBucketHost } from "constant";
import { Artist } from "../artist";

export class Artists {
  static 巫永堅 = new Artist('0', '巫永堅', 'tw',
    `${basicAssetBucketHost}/artist/%E5%B7%AB%E6%B0%B8%E5%A0%85/avatar.jpg`,
    `${basicAssetBucketHost}/artist/%E5%B7%AB%E6%B0%B8%E5%A0%85/profile.jpg`,
    [
      '臺北身心障礙服務推展協會 / 美術顧問',
      '中華美術設計協會 / 理事 / 臺灣',
      '中華平面設計協會 / 理事 / 臺灣',
      '臺灣海報設計協會 / 理事 / 臺灣',
      '中國流行色協會 / 理事 / 北京',
      '國立科技大學 / 任教 / 臺北',
      '國立藝術大學 / 任教 / 臺北',
      '上海大學 數碼藝術學院 / 任教 / 上海',
      '上海師範大學 美術學院 / 任教 / 上海',
      '上海常春藤童裝 / 美術顧問 / 上海',
      '上海滿地企業形象策劃 / 資深顧問 / 上海',
      '廣碩國際貿易 / 董事 / 寧波',
      '上海永堅企業形象策劃 / 總經理 / 上海',
      '上海隱秀文化傳播 / 總經理 / 上海'
    ],
    [
      '2010年中國上海世界博覽會吉祥物原創設計 / 上海',
      '2009年臺北聽障奧林匹克運動會吉祥物設計 / 臺灣',
      '臺北國際青年商會鼠年造型 / 臺北',
      '職棒俊國熊隊俊國熊造型 / 台中',
      '寶島歌王葉啟田競選立委造型 / 臺北',
      '飛龍文具PENTEL龍造型 / 臺北',
      '六福村主題樂園哈比哈妮造型 /新竹',
      '遠雄集團Rich Bear造型 / 臺北',
      '亞洲棒球賽吉祥物造型 / 臺北',
      'NO AIDS 保險套寶寶造型 / 臺北',
      '特奧會特奧龍造型 / 臺北',
      '常春藤童裝WADA系列造型 / 上海'
    ]
  );
  static 黃宏 = new Artist('1', '黃宏', 'tw', '', `${basicAssetBucketHost}/artist/%E9%BB%83%E5%AE%8F/profile.jpg`, [], []);
  static 陶天麟 = new Artist('2', '陶天麟', 'tw', '', `${basicAssetBucketHost}/artist/%E9%99%B6%E5%A4%A9%E9%BA%9F/profile.jpg`, [], []);
  static 人小二 = new Artist('3', '人小二', 'tw', '', `${basicAssetBucketHost}/artist/%E4%BA%BA%E5%B0%8F%E4%BA%8C/profile.jpg`, [], []);
  static 廖芷嬿 = new Artist('4', '廖芷嬿', 'tw', '', `${basicAssetBucketHost}/artist/%E5%BB%96%E8%8A%B7%E5%AC%BF/profile.jpg`, [], []);
  static 謝家雯 = new Artist('5', '謝家雯', 'tw', '', `${basicAssetBucketHost}/artist/%E8%AC%9D%E5%AE%B6%E9%9B%AF/profile.jpg`, [], []);
  static 盧培文 = new Artist('6', '盧培文', 'tw', '', `${basicAssetBucketHost}/artist/%E7%9B%A7%E5%9F%B9%E6%96%87/profile.jpg`, [], []);
  static 李淑娟 = new Artist('7', '李淑娟', 'tw', '', `${basicAssetBucketHost}/artist/%E6%9D%8E%E6%B7%91%E5%A8%9F/profile.jpg`, [], []);
  static 江明哲 = new Artist('8', '江明哲', 'tw', '', `${basicAssetBucketHost}/artist/%E6%B1%9F%E6%98%8E%E5%93%B2/profile.jpg`, [], []);
  static 童豈逸 = new Artist('9', '童豈逸', 'tw', '', `${basicAssetBucketHost}/artist/%E7%AB%A5%E8%B1%88%E9%80%B8/profile.jpg`, [], []);
  static 江明怡 = new Artist('10', '江明怡', 'tw', '', `${basicAssetBucketHost}/artist/%E6%B1%9F%E6%98%8E%E6%80%A1/profile.jpg`, [], []);
  static 黃贊倫 = new Artist('11', '黃贊倫', 'tw', '', `${basicAssetBucketHost}/artist/%E9%BB%83%E8%B4%8A%E5%80%AB/profile.jpg`, [], []);

  static values: Artist[] = [
    Artists.巫永堅, Artists.黃宏,
    Artists.陶天麟, Artists.人小二,
    Artists.廖芷嬿, Artists.謝家雯,
    Artists.盧培文, Artists.李淑娟,
    Artists.江明哲, Artists.童豈逸,
    Artists.江明怡, Artists.黃贊倫
  ];

  static size: number = Artists.values.length;

  static find(uid: string): Artist | null {
    for (const artist of Artists.values) {
      if (artist.uid === uid) return artist;
    }
    return null;
  }
}