import { Subscription, BehaviorSubject } from 'rxjs';
import { News } from 'models';
import { basicAssetBucketHost } from 'constant';

export class NewsBloc {
  private _disposables = new Subscription();

  private _news = new BehaviorSubject<News[]>([]);

  constructor() {
    const list: News[] = [];
    
    list.push(new News(
      '中華宗教藝術人文協會活動花絮',
      '全國性社會團體申請，發起人共：56人',
      `${basicAssetBucketHost}/news/news_01.jpg`,
      '2019/05/15'
    ));
    list.push(new News(
      '中華宗教藝術人文協會准予籌組',
      '准予籌組',
      `${basicAssetBucketHost}/news/news_02.jpg`,
      '2019/07/09'
    ));
    list.push(new News(
      '發起人會議、第一次籌備會議議',
      '決議：1.林璿筑、2.李沛林、3.張志源、4.楊湘婷、5.李麗端、6.簡淑麗、7.李文正，等7人擔任籌備委員。',
      `${basicAssetBucketHost}/news/news_03.jpg`,
      '2019/08/15'
    ));
    list.push(new News(
      '第二次籌備會會議',
      '第二次籌備會會議',
      `${basicAssetBucketHost}/news/news_04.jpg`,
      '2019/09/15'
    ));
    list.push(new News(
      '第一屆第一次會員大會及理監事聯席會',
      '第一屆第一次會員大會及理監事聯席會',
      `${basicAssetBucketHost}/news/news_05.jpg`,
      '2019/10/19'
    ));
    list.push(new News(
      '成立大會報請主管機關',
      '成立大會報請主管機關',
      `${basicAssetBucketHost}/news/news_01.jpg`,
      '2019/10/28'
    ));
    list.push(new News(
      '准予立案',
      '台內團字第1080070942',
      `${basicAssetBucketHost}/news/news_02.jpg`,
      '2019/11/15'
    ));
    
    this._news.next(list.reverse());
  }

  get news() { return this._news; }

  dispose(): void {
    this._disposables.unsubscribe();
  }
}