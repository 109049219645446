import intl from 'react-intl-universal';

export class Artist {
  private _uid: string;
  private _name: string;
  private _countryCode: string;
  private _avatar: string;
  private _image: string;
  private _titles: string[];
  private _works: string[];

  constructor(uid: string, name: string, countryCode: string,
    avatar: string, image: string,
    titles: string[], works: string[]) {
    this._uid = uid;
    this._name = name;
    this._countryCode = countryCode;
    this._avatar = avatar;
    this._image = image;
    this._titles = titles;
    this._works = works;
  }

  get uid() { return this._uid; }

  get name() { return this._name; }

  get countryCode() { return this._countryCode; }

  get country() {
    switch (this._countryCode) {
      case 'cn': return intl.get('china');
      default: return intl.get('taiwan');
    }
  }

  get avatar() { return this._avatar; }

  get image() { return this._image; }

  get titles() { return this._titles; }

  get works() { return this._works; }
}