export class Painting {
  private _id: string;
  private _artistUid: string;
  private _name: string;
  private _url: string;

  constructor(id: string, artistUid: string,
    name: string, url: string) {
    this._id = id;
    this._artistUid = artistUid;
    this._name = name;
    this._url = url;
  }

  get id() { return this._id; }

  get artistUid() { return this._artistUid; }

  get name() { return this._name; }

  get url() { return this._url; }

}