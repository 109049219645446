import * as React from "react"

export const IcLine = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 67.43 66.88"
      {...props}
    >
      <g>
        <rect
          fill={props.color}
          width={67.43}
          height={66.88}
          rx={33.44}
          ry={33.44}
        />
        <g>
          <path
            d="M58.15 30.5c0-10.9-11-19.76-24.55-19.76S9.05 19.6 9.05 30.5c0 9.77 8.74 18 20.54 19.49.8.17 1.88.53 2.16 1.2a5 5 0 01.08 2.21s-.29 1.71-.35 2.08c-.11.62-.5 2.41 2.12 1.31s14.14-8.25 19.29-14.13c3.56-3.87 5.26-7.8 5.26-12.16"
            fill="#fff"
          />
          <path
            fill={props.color}
            d="M28.62 25.23h-1.73a.48.48 0 00-.47.48v10.61a.47.47 0 00.47.47h1.73a.47.47 0 00.47-.47V25.71a.48.48 0 00-.47-.48M40.47 25.23h-1.72a.48.48 0 00-.48.48V32l-4.9-6.57h-2a.48.48 0 00-.48.48v10.41a.47.47 0 00.48.47H33a.48.48 0 00.48-.47V30l4.9 6.57a.49.49 0 00.13.12h1.96a.48.48 0 00.48-.47V25.71a.49.49 0 00-.48-.48M24.47 34.13h-4.68v-8.42a.49.49 0 00-.48-.48h-1.72a.48.48 0 00-.48.48v10.61a.46.46 0 00.13.32.47.47 0 00.33.13h6.88a.47.47 0 00.47-.47v-1.69a.48.48 0 00-.47-.48M50 27.89a.48.48 0 00.48-.47v-1.71a.49.49 0 00-.48-.48h-6.9a.44.44 0 00-.33.14.49.49 0 00-.13.33v10.62a.46.46 0 00.13.32.47.47 0 00.33.13H50a.48.48 0 00.48-.47v-1.69a.49.49 0 00-.48-.48h-4.7v-1.79H50a.48.48 0 00.48-.47v-1.71a.49.49 0 00-.48-.48h-4.7v-1.79z"
          />
        </g>
      </g>
    </svg>
  )
};