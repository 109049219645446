import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core';
import { Layout } from 'component/common/layout';
import { BtnText } from 'component/common/btn_text';

import { Dimens, Colors } from 'styles';
import intl from 'react-intl-universal';

import { LogoW } from 'assets';

const Menus = [
  { key: 'about', nameId: 'footer_about', },
  { key: 'privacy', nameId: 'footer_privacy', },
  { key: 'service', nameId: 'footer_service', },
  { key: 'qa', nameId: 'footer_qa', },
  { key: 'contact', nameId: 'footer_contact', },
];

const SmallMenus = [
  { key: 'privacy', nameId: 'footer_privacy', },
  { key: 'service', nameId: 'footer_service', },
  { key: 'qa', nameId: 'footer_qa', },
];

export const Footer: React.FC = () => {
  const smallScreen = !useMediaQuery('(min-width: 768px)');
  const css = useCss({ smallScreen });

  /* --- views --- */

  const menus = smallScreen ? SmallMenus : Menus;

  const menuView = () => (
    <Layout
      direction='row'>
      {menus.map((menu, index) => {
        let clz = `${css.footerMenu}`;
        if (index === 0) {
          clz = `${css.footerMenu} ${css.footerMenuFirst}`;
        } else if (index === Menus.length - 1) {
          clz = `${css.footerMenu} ${css.footerMenuEnd}`;
        }
        return (
          <Fragment key={menu.key}>
            <BtnText
              elementType='a'
              fontSize={Dimens.textS}
              color={Colors.textW}
              txt={intl.get(menu.nameId)}
              className={clz} />
            {index === Menus.length - 1 ? null : <Layout width={Dimens.divider} className={css.footerMenuDivider} />}
          </Fragment>
        );
      })}
    </Layout>
  );

  const copyrightView = () => (
    <Layout
      alignSelf={smallScreen ? 'center' : undefined}
      alignItems='center'
      direction={smallScreen ? 'column' : 'row'}
      className={css.footerCopyrightRoot}>
      <span className={css.footerCopyright}>{intl.get('footer_copyright')}</span>
      <span className={css.footerReserved}>{intl.get('footer_reserved')}</span>
    </Layout>
  );

  return (
    <Layout
      itemScope
      itemType='http://schema.org/WPFooter'
      flex={1}
      direction='row'
      alignItems='center'
      justifyContent={smallScreen ? 'center' : 'space-between'}>
      {smallScreen ? null : (
        <img
          src={LogoW}
          alt='logo_footer'
          className={css.logo} />
      )}
      <Layout>
        {menuView()}
        {copyrightView()}
      </Layout>
    </Layout>
  );
};

type CssProps = {
  smallScreen: boolean;
};

const useCss = makeStyles<{}, CssProps>({
  root: {
    backgroundColor: Colors.footer,
  },
  logo: {
    height: `calc(${Dimens.appbar} * 0.85)`,
  },
  footerMenu: {
    fontSize: props => props.smallScreen ? Dimens.textXS : Dimens.textS,
    color: Colors.textW,
    margin: `0px ${Dimens.marginS}`,
  },
  footerMenuFirst: {
    margin: `0px ${Dimens.marginS} 0px 0px`,
  },
  footerMenuEnd: {
    margin: `0px 0px 0px ${Dimens.marginS}`,
  },
  footerMenuDivider: {
    alignSelf: 'stretch',
    backgroundColor: '#ffffff',
    margin: '3px 0px',
  },
  footerCopyrightRoot: {
    marginTop: Dimens.marginXS,
  },
  footerCopyright: {
    fontSize: Dimens.textXS,
    letterSpacing: '0px',
    color: Colors.textW,
  },
  footerReserved: {
    fontSize: Dimens.textXS,
    letterSpacing: '0px',
    color: Colors.textW,
    marginLeft: props => props.smallScreen ? '0px' : Dimens.marginS,
  },
})