import { Subscription, BehaviorSubject } from 'rxjs';
import { Report } from 'models';
import { basicAssetBucketHost } from 'constant';

export class ReportsBloc {
  private _disposables = new Subscription();

  private _reports = new BehaviorSubject<Report[]>([]);

  constructor() {
    const list: Report[] = [];
    for (let i = 0; i < 5; i++) {
      list.push(new Report(
        '文協會開幕報導',
        `
          對於藝術人文，我們擁有更遠的展望。CRAH並不
          偏限自己僅是藝術品，我們積極推廣人文藝術在生活
          中能展現，且定期提供精選展覽文章及收藏訊息，希
          望瀏覽者能不受限， ...................
        `,
        `${basicAssetBucketHost}/reports/reports_0${5 - i}.jpg`,
        '2019/01/21'
      ));
    }
    this._reports.next(list);
  }

  get reports() { return this._reports; }

  dispose(): void {
    this._disposables.unsubscribe();
  }
}