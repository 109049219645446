import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import './index.css';
import { App } from 'pages';
import { I18N } from 'utils';

import * as serviceWorker from './serviceWorker';

I18N.init();

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

const rootElement = document.getElementById("root");
if (rootElement?.hasChildNodes()) {
  ReactDOM.hydrate(
    <React.StrictMode>
      <BrowserRouter>
        <LastLocationProvider>
          <App />
        </LastLocationProvider>
      </BrowserRouter>
    </React.StrictMode>
    , rootElement);
} else {
  ReactDOM.render(
    <React.StrictMode>
      <BrowserRouter>
        <LastLocationProvider>
          <App />
        </LastLocationProvider>
      </BrowserRouter>
    </React.StrictMode>
    , rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
