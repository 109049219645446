import React, { HTMLProps } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { useMediaQuery, Paper, ButtonBase } from '@material-ui/core';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Layout } from 'components';

import { News } from 'models';

import { Dimens } from 'styles';

type ScreenSize = 'small' | 'medium' | 'large';

const getScreenSize = (less768: boolean, less1000: boolean): ScreenSize => {
  if (less768) return 'small';
  if (less1000) return 'medium';
  return 'large';
}

interface Props extends HTMLProps<HTMLElement> {
  news: News;
  onSelected?: (news: News) => void;
}

export const NewsCard: React.FC<Props> = ({
  style,
  className,
  news,
  onSelected,
}) => {
  const less768 = !useMediaQuery('(min-width: 768px)');
  const less1000 = !useMediaQuery('(min-width: 1000px)');
  const screenSize = getScreenSize(less768, less1000);

  const css = useCss({ screenSize });

  const view = () => {
    return (
      <>
        <Layout
          flex={screenSize === 'small' ? 4 : 2.5}
          className={css.imageRoot}>
          <LazyLoadImage
            width='100%'
            height='100%'
            effect='blur'
            src={news.imageUrl}
            alt={news.title}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              objectFit: 'cover',
              objectPosition: 'center',
            }} />
          <Layout
            alignItems='center'
            justifyContent='center'
            className={css.dateRoot}>
            <span className={css.day}>{news.day}</span>
            <span className={css.yearMonth}>{news.yearMonth}</span>
          </Layout>
        </Layout>
        <Layout
          flex={7.5}
          direction='column'
          alignItems='stretch'
          className={css.body}>
          <h2 className={css.title}>{news.title}</h2>
          <span className={clsx(css.content, screenSize === 'small' ? css.line4 : css.line2)}>
            {news.content}
          </span>
        </Layout>
      </>
    );
  };

  return (
    <ButtonBase
      style={style}
      className={className}
      onClick={() => {
        if (onSelected) {
          onSelected(news);
        }
      }}>
      <Paper
        elevation={4}
        className={css.root}>
        {view()}
      </Paper>
    </ButtonBase>
  );
};

type CssProps = {
  screenSize: 'small' | 'medium' | 'large';
};

const useCss = makeStyles<
  {},
  CssProps,
  'root' | 'body' |
  'imageRoot' | 'dateRoot' |
  'title' | 'content' | 'line2' | 'line4' |
  'day' | 'yearMonth' | 'btnMore'
>({
  root: {
    display: 'flex',
    width: '100%',
    margin: '8px',
    // flexDirection: props => props.screenSize === 'small' ? 'column' : 'row',
    flexDirection: 'row',
    boxSizing: 'border-box',
    alignItems: 'stretch',
    borderRadius: '0px',
  },
  imageRoot: {
    position: 'relative',
    // minWidth: '150px',
  },
  dateRoot: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
  },
  body: {
    alignItems: 'flex-start',
    padding: props =>
      props.screenSize === 'small' ?
        `${Dimens.marginS} ${Dimens.marginM} ${Dimens.marginS} ${Dimens.marginM}` :
        Dimens.marginM,
  },
  title: {
    alignSelf: 'flex-start',
    display: '-webkit-box',
    boxOrient: 'vertical',
    fontFamily: "'Noto Serif TC', serif",
    fontSize: props => props.screenSize === 'small' ? Dimens.newsCardTitle_RWD : Dimens.newsCardTitle,
    fontWeight: 'bold',
    letterSpacing: '3px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineClamp: 1,
  },
  content: {
    minHeight: props => props.screenSize === 'small' ? '100px' : '50px',
    display: '-webkit-box',
    boxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginTop: props => props.screenSize === 'small' ? Dimens.marginS : Dimens.marginM,
    textAlign: 'left',
  },
  line2: {
    lineClamp: 2,
  },
  line4: {
    lineClamp: 4,
  },
  day: {
    fontFamily: "'Noto Serif TC', serif",
    fontSize: props => props.screenSize === 'small' ? Dimens.newsCardDay_RWD : Dimens.newsCardDay,
    fontWeight: 'bold',
    color: '#fff',
  },
  yearMonth: {
    fontFamily: "'Noto Serif TC', serif",
    fontSize: props => props.screenSize === 'small' ? Dimens.newsCardYearMonth_RWD : Dimens.newsCardYearMonth,
    fontWeight: 'bold',
    color: '#fff',
    marginTop: props => props.screenSize === 'small' ? Dimens.marginS : Dimens.marginM,
  },
  btnMore: {
    alignSelf: 'flex-end',
    marginTop: props => props.screenSize === 'small' ? Dimens.marginM : Dimens.marginL,
  },
});