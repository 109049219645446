import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Layout, PageHeader, PageIntro, Footer } from 'components';

import { Dimens } from 'styles';
import intl from 'react-intl-universal';
import { basicAssetBucketHost } from 'constant';

const ImageBanner = `${basicAssetBucketHost}/about/banner_about.jpg`;
const IntroTitle = '中華宗教藝術人文協會';
const IntroDesc = `前言：我們不以教主一詞來尊稱李麗鳳女士，
多數人都叫二姊(李家子女中排行第二)。<br>遵奉九蓮佛組之法旨，
以實際真經道德規範，以【重倫理，講報本】為宗旨。
育化群生以真誠的開懷與愛心及互助、互敬、互勉之心境利澤蒼生，
引導宣化知覺之道，以省思懺悔正信勵實，正心堅忍，正念心閣，
改正淨化社會風氣，敬重先人各家堂訓禮教敬神拜祖之精神，代效傳承，
振興祖先法脈立堂中興，立德善功，願奉獻自己之心力，濟貧救困關心鄉里，
寧靜十方祈安太平，真心教導尊師重道之精神與認祖歸宗之人生觀念,關懷協助，
經營計劃，幫忙弱勢眾生，佈施福田，立行為善，「道心為己心,以師志為己志」
的深切體認效為。`;
const IdealImage = `${basicAssetBucketHost}/about/about_01.jpg`;
const IdealDesc = '直至今日，從未間斷。助人無數。至今已有眾多朋友、鄉親、十方友人都在二姊無私、無償、熱心好善助人的慈心下受之恩德幫助。以此眾人願以二姊的精神為首，發揮更多的大愛及宣化更多二姊的精神裡念,幫助更多的人。'
const HopeImage = `${basicAssetBucketHost}/about/about_02.jpg`;
const HopeDesc = '二姊認為宗教是一種藝術跟人文的結合，我們的家庭文化其實就是藝術人文的文化，相對的如何用藝術的素養去提升人文的品格涵養,自然家庭文化自然能真善美。<br><br>56人發起了籌組『中華宗教藝術人文協會』';

type Props = {
  appbarHeight: string;
};

export const AboutPage: React.FC<Props> = ({
  appbarHeight,
}) => {
  const smallScreen = !useMediaQuery('(min-width: 768px)');
  const css = useCss({ appbarHeight, smallScreen });

  /* --- lifecycle --- */

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  /* --- views --- */

  const idealViews = () => (
    <section className={css.ideal}>
      <Layout
        flex={1}
        alignItems='flex-start'
        style={{ marginRight: smallScreen ? '0px' : Dimens.marginL }}>
        <h2 className={css.idealTitle}>我們理念</h2>
        <span className={css.idealDesc} dangerouslySetInnerHTML={{ __html: IdealDesc }} />
      </Layout>
      {smallScreen ? null : (
        <Layout
          flex={1}
          height={Dimens.aboutIdealImageH}
          style={{ marginLeft: Dimens.marginS }}>
          <LazyLoadImage
            width='100%'
            height='100%'
            effect='blur'
            src={IdealImage}
            alt={`ideal`}
            style={{
              objectFit: 'cover',
              objectPosition: 'center',
            }} />
        </Layout>
      )}
    </section>
  );

  const hopeViews = () => (
    <section className={css.hope}>
      {smallScreen ? null : (
        <Layout
          flex={1}
          height={Dimens.aboutHopeImageH}
          style={{ marginRight: Dimens.marginS }}>
          <LazyLoadImage
            width='100%'
            height='100%'
            effect='blur'
            src={HopeImage}
            alt={`hope`}
            style={{
              objectFit: 'cover',
              objectPosition: 'center',
            }} />
        </Layout>
      )}
      <Layout
        flex={1}
        alignItems='flex-start'
        style={{ marginLeft: smallScreen ? '0px' : Dimens.marginL }}>
        <h2 className={css.hopeTitle}>我們願景</h2>
        <span className={css.hopeDesc} dangerouslySetInnerHTML={{ __html: HopeDesc }} />
      </Layout>
    </section>
  );

  return (
    <>
      <main className={css.root}>
        <PageHeader
          imageUrl={ImageBanner}
          imageAlt='about_banner'
          title={intl.get('about_section_header_title')}
          subTitle={intl.get('about_section_header_title_sub')}
        />
        <PageIntro
          title={IntroTitle}
          desc={IntroDesc}
        />
        {idealViews()}
        {hopeViews()}
      </main >
      <footer>
        <Footer />
      </footer>
    </>
  );
};

type CssProps = {
  appbarHeight: string;
  smallScreen: boolean;
};

const useCss = makeStyles<
  {},
  CssProps,
  'root' |
  'ideal' | 'idealTitle' | 'idealDesc' |
  'hope' | 'hopeTitle' | 'hopeDesc'
>({
  root: {
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    alignItems: 'stretch',
    marginTop: props => props.appbarHeight,
    minHeight: props => `calc(100vh - ${props.appbarHeight} - ${Dimens.footer})`,
  },
  ideal: {
    flexDirection: 'row',
    alignSelf: 'center',
    width: props => props.smallScreen ? '90%' : '65%',
    padding: `${Dimens.marginS} 0px`,
  },
  idealTitle: {
    alignSelf: props => props.smallScreen ? 'center' : 'flex-start',
    fontFamily: "'Noto Serif TC', serif",
    fontSize: props => props.smallScreen ? Dimens.textL_RWD : Dimens.textL,
    fontWeight: 'bold',
    letterSpacing: '3px',
    margin: `${Dimens.marginS} 0px`,
  },
  idealDesc: {
    marginTop: Dimens.marginM,
  },
  hope: {
    flexDirection: 'row',
    alignSelf: 'center',
    width: props => props.smallScreen ? '90%' : '65%',
    padding: `${Dimens.marginS} 0px`,
    marginTop: Dimens.marginL,
    marginBottom: `calc(${Dimens.marginL} * 3)`,
  },
  hopeTitle: {
    alignSelf: props => props.smallScreen ? 'center' : 'flex-start',
    fontFamily: "'Noto Serif TC', serif",
    fontSize: props => props.smallScreen ? Dimens.textL_RWD : Dimens.textL,
    fontWeight: 'bold',
    letterSpacing: '3px',
    margin: `${Dimens.marginS} 0px`,
  },
  hopeDesc: {
    marginTop: Dimens.marginM,
  },
});