import React from 'react';
import styled from 'styled-components';
import { ThemeProvider } from '@material-ui/styles';
import { Button as MaterialButton, createMuiTheme } from '@material-ui/core';

import { Dimens, Colors } from 'styles';
import ColorUtil from 'color';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  width?: string;
  height?: string;
  padding?: string;
  round?: 'none' | 'corner' | 'circle';
  disable?: boolean;
  txt?: string;
  color?: string;
  disableColor?: string;
  fontSize?: string;
  txtColor?: string;
  onClick?: () => void;
};

export const Btn: React.FC<Props> = ({
  className,
  style,
  width = 'auto',
  height = Dimens.btn,
  padding,
  round = 'corner',
  disable = false,
  txt = '',
  color = Colors.primary,
  disableColor = Colors.disable,
  fontSize = Dimens.textM,
  txtColor = Colors.textW,
  onClick,
}: Props) => {
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: color,
        contrastText: txtColor,
      },
      secondary: {
        main: color,
        contrastText: txtColor,
      },
    },
  });

  const getRoundRadius = () => {
    switch (round) {
      case 'corner':
        return Dimens.corner;
      case 'circle':
        return `calc(${height} / 2)`;
    }
    return '0px';
  };

  return (
    <ThemeProvider theme={theme}>
      <Button
        className={`${className}`}
        style={style}
        variant='contained'
        color='primary'
        disabled={disable}
        width={width}
        height={height}
        inner_padding={padding}
        round_radius={getRoundRadius()}
        btn_color={color}
        disable_color={disableColor}
        font_size={fontSize}
        txt_color={txtColor}
        onClick={() => {
          if (onClick) {
            onClick();
          }
        }}>
        {txt}
      </Button>
    </ThemeProvider>
  );
};

type ButtonProps = {
  width: string;
  height: string;
  inner_padding?: string;
  round_radius: string;
  btn_color: string;
  disable_color: string;
  font_size: string;
  txt_color: string;
};

const Button = styled(MaterialButton)<ButtonProps>`
  &.MuiButton-root {
      min-width: ${props => props.width};
  }
  &.MuiButton-containedPrimary {
      background-color: ${props => props.btn_color};
  }
  &.MuiButton-containedPrimary:hover {
      background-color: ${props => (new ColorUtil(props.btn_color)).darken(0.15).hex()};
  }
  &.MuiButton-contained.Mui-disabled {
      background-color: ${props => props.disable_color};
  }
  & > span {
    user-select: none;
    color: ${props => props.txt_color};
  }
  height: ${props => props.height};
  width: ${props => props.width};
  padding: ${props => props.inner_padding};
  outline: none;
  font-size: ${props => props.font_size};
  && {
    border-radius: ${props => props.round_radius}; 
  }
`;
