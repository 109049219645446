import intl from 'react-intl-universal';
import { zh_tw } from 'styles';

const LocalizedLanguages = {
  'zh_tw': zh_tw,
};

export class I18N {
  static init() {
    let language = 'zh_tw';
    return intl.init({
      currentLocale: language,
      locales: LocalizedLanguages,
    });
  }
}
