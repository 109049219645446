import React, { useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { 
  HomeBanner, 
  HomeArtworks, 
  HomeAbout, 
  HomeContact, 
  Footer 
} from 'components';

import { Dimens } from 'styles';
import intl from 'react-intl-universal';
import { HomeBloc } from 'pages';

export type HomeContextType = {
  bloc?: HomeBloc;
}
export const HomeContext = React.createContext<HomeContextType>({});

type Props = {
  appbarHeight: string;
};

export const HomePage: React.FC<Props> = ({
  appbarHeight,
}) => {
  const css = useCss({ appbarHeight });

  const bloc = useRef<HomeBloc>(new HomeBloc());

  /* --- lifecycle --- */

  useEffect(() => {
    window.scrollTo(0, 0);
    const _bloc = bloc.current;
    return () => {
      _bloc.dispose();
    };
  }, []);

  /* --- views --- */

  return (
    <HomeContext.Provider value={{ bloc: bloc.current }}>
      <>
        <main className={css.root}>
          <HomeBanner />
          {/* --- 藝術品 --- */}
          <section>
            <h1>{intl.get('home_section_artworks_title')}</h1>
            <h2>{intl.get('home_section_artworks_title_sub')}</h2>
            <HomeArtworks />
          </section>
          {/* --- 關於我們 --- */}
          <section className={css.sectionAbout}>
            <HomeAbout />
          </section>
          {/* --- 聯絡我們 --- */}
          <section>
            <h1>{intl.get('home_section_contact_title')}</h1>
            <h2>{intl.get('home_section_contact_title_sub')}</h2>
            <HomeContact />
          </section>
        </main>
        <footer>
          <Footer />
        </footer>
      </>
    </HomeContext.Provider>
  );
};

type CssProps = {
  appbarHeight: string;
};

const useCss = makeStyles<{}, CssProps>({
  root: {
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    alignItems: 'stretch',
    marginTop: props => props.appbarHeight,
    minHeight: props => `calc(100vh - ${props.appbarHeight} - ${Dimens.footer})`,
  },
  sectionAbout: {
    padding: '0px',
  },
});