import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core';
import { BlocBuilder, Layout, PageHeader, Footer, Pagination, ReportsCard } from 'components';

import intl from 'react-intl-universal';
import { Dimens } from 'styles';
import { ReportsBloc } from 'pages';
import { basicAssetBucketHost } from 'constant';

const ImageBanner = `${basicAssetBucketHost}/reports/reports_banner.jpg`;

type Props = {
  appbarHeight: string;
};

const getScreenType = (small: boolean, medium: boolean) => {
  if (small && medium) { return 'small'; }
  if (medium) { return 'medium'; }
  return 'large';
}

export const ReportsPage: React.FC<Props> = ({
  appbarHeight,
}) => {
  const screenSmall = !useMediaQuery('(min-width: 768px)');
  const screenMedium = !useMediaQuery('(min-width: 1000px');
  const screenType: 'small' | 'medium' | 'large' = getScreenType(screenSmall, screenMedium);
  const lines = screenType === 'small' ? 2 : 4;

  const css = useCss({ appbarHeight, screenType, lines });

  const bloc = useRef<ReportsBloc>(new ReportsBloc());

  /* --- lifecycle --- */

  useEffect(() => {
    const _bloc = bloc.current;
    window.scrollTo(0, 0);
    return () => {
      _bloc.dispose();
    };
  }, []);

  /* --- views --- */

  return (
    <>
      <main className={css.root}>
        <PageHeader
          imageUrl={ImageBanner}
          imageAlt='news_banner'
          title={intl.get('reports_section_header_title')}
          subTitle={intl.get('reports_section_header_title_sub')}
        />
        <section className={css.content}>
          <BlocBuilder
            initialValue={bloc.current.reports.getValue()}
            subject={bloc.current.reports}
            builder={snapshot => {
              return (
                <Layout alignItems='stretch'>
                  {snapshot.data.map((report, index) => {
                    return (
                      <ReportsCard
                        key={`report_${index}`}
                        report={report}
                        style={{ marginTop: index === 0 ? 0 : Dimens.marginL }} />
                    );
                  })}
                </Layout>
              );
            }}
          />
          <Layout
            direction='row'
            justifyContent='center'
            className={css.pagination}>
            <Pagination pageCount={1} onPageChange={(index) => { console.log('page selected', index) }} />
          </Layout>
        </section>
      </main>
      <footer>
        <Footer />
      </footer>
    </>
  );
};

type CssProps = {
  appbarHeight: string;
  screenType: 'small' | 'medium' | 'large';
  lines: number;
}

const useCss = makeStyles<
  {},
  CssProps,
  'root' | 'content' | 'pagination'
>({
  root: {
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    alignItems: 'stretch',
    marginTop: props => props.appbarHeight,
    minHeight: props => `calc(100vh - ${props.appbarHeight} - ${Dimens.footer})`,
  },
  content: {
    alignSelf: 'center',
    alignItems: 'stretch',
    width: props => {
      if (props.screenType === 'large') return '65%';
      if (props.screenType === 'medium') return '80%';
      return '90%';
    },
    padding: props => props.screenType === 'small' ? `${Dimens.marginM} 0px ${Dimens.marginL} 0px` : `calc(${Dimens.marginL} * 2) 0px`,
  },
  pagination: {
    marginTop: Dimens.marginM,
  }
});
