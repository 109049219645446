import React, { Fragment, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core';
import { BlocBuilder, Layout, NewsCard, Pagination } from 'components';

import { Dimens } from 'styles';
import { NewsContext } from 'utility/context';

export const NewsListPage: React.FC = () => {
  const history = useHistory();
  const bloc = useContext(NewsContext).bloc!;

  const css = useCss();

  return (
    <Fragment>
      <BlocBuilder
            initialValue={bloc.news.getValue()}
            subject={bloc.news}
            builder={snapshot => {
              return (
                <Layout alignItems='stretch'>
                  {snapshot.data.map((item, index) => {
                    return (
                      <NewsCard
                        key={`news_${index}`}
                        news={item}
                        style={{ marginTop: index === 0 ? 0 : Dimens.marginM }}
                        onSelected={(news) => {
                          const pageIndex = snapshot.data.length - 1 - index;
                          history.push(`/news?index=${pageIndex}`);
                        }} />
                    );
                  })}
                </Layout>
              );
            }}
          />
          <Layout
            direction='row'
            justifyContent='center'
            className={css.pagination}>
            <Pagination pageCount={1} onPageChange={(index) => { console.log('page selected', index) }} />
          </Layout>
    </Fragment>
  );
};

const useCss = makeStyles({
  pagination: {
    marginTop: Dimens.marginM,
  },
});
