import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core';

import { Dimens } from 'styles';

type Props = {
  title: string;
  desc: string;
};

export const PageIntro: React.FC<Props> = ({
  title,
  desc,
}) => {
  const smallScreen = !useMediaQuery('(min-width: 768px)');
  const css = useCss({ smallScreen });

  return (
    <section className={css.root}>
      <h2 className={css.title}>{title}</h2>
      <span className={css.desc} dangerouslySetInnerHTML={{ __html: desc }} />
    </section>
  );
};

type CssProps = {
  smallScreen: boolean;
}

const useCss = makeStyles<
  {},
  CssProps,
  'root' | 'title' | 'desc'
>({
  root: {
    alignSelf: 'center',
    width: props => props.smallScreen ? '90%' : '65%',
    textAlign: 'center',
    padding: `${Dimens.marginM} 0px`,
  },
  title: {
    fontFamily: "'Noto Serif TC', serif",
    fontWeight: 'bold',
    fontSize: props => props.smallScreen ? Dimens.textXL_RWD : Dimens.textXL,
    letterSpacing: '3px',
    margin: props => `${props.smallScreen ? Dimens.marginM : Dimens.marginL} 0px`,
  },
  desc: {
    marginBottom: Dimens.marginL,
    textAlign: 'left',
  },
});
