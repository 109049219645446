import * as React from "react"

export const IcYoutube = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 67.43 67.43"
      {...props}
    >
      <rect
        fill={props.color}
        width={67.43}
        height={67.43}
        rx={33.71}
        ry={33.71}
      />
      <path
        d="M56.84 22.48c-.88-3.6-2.56-5-5.67-5.51-1.79-.27-10.38-.65-17.2-.68-6.82 0-15.42.27-17.21.52-3.11.49-4.8 1.87-5.72 5.46a92.41 92.41 0 00-.77 10.37v.55A92.87 92.87 0 0011 43.56c.89 3.61 2.57 5 5.67 5.52 1.79.27 10.38.65 17.2.68 6.83 0 15.42-.27 17.21-.52 3.11-.49 4.81-1.87 5.72-5.46a90.09 90.09 0 00.77-10.37v-.55a90.88 90.88 0 00-.73-10.38z"
        fill="#fff"
      />
      <path
        fill={props.color}
        d="M29.04 26.22l12.51 6.6-12.58 6.81.07-13.41z"
      />
      <path
        fill="#3a3a3a"
        d="M29.04 26.22l12.51 6.6-1.44.78-11.07-7.38z"
      />
    </svg>
  )
};