import React from 'react';
import ReactPaginate from 'react-paginate';

import IcPrev from '@material-ui/icons/ArrowBackIosRounded';
import IcNext from '@material-ui/icons/ArrowForwardIosRounded';

type Props = {
  pageCount: number;
  marginPagesDisplayed?: number;
  pageRangeDisplayed?: number;
  onPageChange: (index: number) => void;
};

export const Pagination: React.FC<Props> = ({
  pageCount,
  marginPagesDisplayed = 1,
  pageRangeDisplayed = 5,
  onPageChange,
}) => {
  return (
    <ReactPaginate
      previousLabel={
        <IcPrev style={{
          display: 'block',
          margin: 'auto',
          width: '15px',
          height: '15px'
        }} />
      }
      nextLabel={
        <IcNext style={{
          display: 'block',
          margin: 'auto',
          width: '15px',
          height: '15px'
        }} />
      }
      breakLabel={'...'}
      breakClassName={'break'}
      pageCount={pageCount}
      marginPagesDisplayed={marginPagesDisplayed}
      pageRangeDisplayed={pageRangeDisplayed}
      onPageChange={({ selected }) => {
        if (onPageChange) {
          onPageChange(selected);
        }
      }}
      containerClassName={'pagination'}
      activeClassName={'active'}
    />
  );
};