import React, { useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import QueryString from 'query-string';
import { makeStyles } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core';
import { BlocBuilder, Layout, PageHeader, Footer, NewsCard, Pagination } from 'components';

import { Dimens } from 'styles';
import intl from 'react-intl-universal';
import {
  NewsBloc,
  NewsListPage,
  News00Page,
  News01Page,
  News02Page,
  News03Page,
  News04Page,
  News05Page,
  News06Page,
} from 'pages';
import { NewsContext } from 'utility/context';
import { basicAssetBucketHost } from 'constant';

const ImageBanner = `${basicAssetBucketHost}/news/news_banner.jpg`;

type Props = {
  appbarHeight: string;
};

const getScreenType = (small: boolean, medium: boolean) => {
  if (small && medium) { return 'small'; }
  if (medium) { return 'medium'; }
  return 'large';
}

export const NewsPage: React.FC<Props> = ({
  appbarHeight,
}) => {
  const location = useLocation();
  const search = QueryString.parse(location.search, { parseNumbers: true });

  const screenSmall = !useMediaQuery('(min-width: 768px)');
  const screenMedium = !useMediaQuery('(min-width: 1000px');
  const screenType: 'small' | 'medium' | 'large' = getScreenType(screenSmall, screenMedium);
  const css = useCss({ appbarHeight, screenType });

  const bloc = useRef<NewsBloc>(new NewsBloc());

  /* --- lifecycle --- */

  useEffect(() => {
    const _bloc = bloc.current;
    window.scrollTo(0, 0);
    return () => {
      _bloc.dispose();
    };
  }, []);

  /* --- views --- */

  const pageView = (index: number) => {
    switch (index) {
      case 0:
        return <News00Page />;
      case 1:
        return <News01Page />;
      case 2:
        return <News02Page />;
      case 3:
        return <News03Page />;
      case 4:
        return <News04Page />;
      case 5:
        return <News05Page />;
      case 6:
        return <News06Page />;
    }
    return null;
  }

  return (
    <NewsContext.Provider value={{ bloc: bloc.current }}>
      <>
        <main className={css.root}>
          <PageHeader
            imageUrl={ImageBanner}
            imageAlt='news_banner'
            title={intl.get('news_section_header_title')}
            subTitle={intl.get('news_section_header_title_sub')}
          />
          {typeof search?.index === 'number' && search?.index >= 0 ? pageView(search.index as number) :
            (
              <section className={css.content}>
                <NewsListPage />
              </section>
            )
          }
        </main>
        <footer>
          <Footer />
        </footer>
      </>
    </NewsContext.Provider>
  );
};

type CssProps = {
  appbarHeight: string;
  screenType: 'small' | 'medium' | 'large';
}

const useCss = makeStyles<
  {},
  CssProps,
  'root' | 'content' | 'pagination'
>({
  root: {
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    alignItems: 'stretch',
    marginTop: props => props.appbarHeight,
    minHeight: props => `calc(100vh - ${props.appbarHeight} - ${Dimens.footer})`,
  },
  content: {
    alignSelf: 'center',
    alignItems: 'stretch',
    width: props => {
      if (props.screenType === 'large') return '65%';
      if (props.screenType === 'medium') return '80%';
      return '90%';
    },
    padding: props => props.screenType === 'small' ? `${Dimens.marginM} 0px ${Dimens.marginL} 0px` : `calc(${Dimens.marginL} * 2) 0px`,
  },
  pagination: {
    marginTop: Dimens.marginM,
  }
});
