import React, { useRef, useEffect } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
// import { Helmet } from 'react-helmet';
import { useMediaQuery, AppBar } from '@material-ui/core';
import { BlocBuilder, Layout, AppbarMenu, AppbarDrawer } from 'components';

import ColorUtils from 'color';
import { Menu } from 'models';

import './App.css';
import { Dimens, Colors } from 'styles';
import {
  AppBloc,
  HomePage,
  AboutPage,
  HumanitiesPage,
  ReligionPage,
  NewsPage,
  ReportsPage,
  ArtPage,
  ArtistPage,
  ArtistDetailPage,
} from 'pages';

import { Logo } from 'assets';
import { MenuRounded as IcMenu } from '@material-ui/icons';
import { } from 'component/common/bloc_builder';

type ViewId = {
  btnHome: string;
  btnMenu: string;
};

export const App: React.FC & {
  Id: ViewId;
} = () => {
  const history = useHistory();

  const screenL = useMediaQuery('(min-width: 1000px)');
  const screenM = useMediaQuery('(min-width: 768px)');
  const smallScreen = !screenL && !screenM;

  const bloc = useRef<AppBloc>(new AppBloc());

  /* --- lifecycle --- */

  useEffect(() => {
    const _bloc = bloc.current;
    return () => {
      _bloc.dispose();
    };
  }, []);

  /* --- functions --- */

  const btnClicked = (id: string) => {
    switch (id) {
      case App.Id.btnHome:
        history.push('/home');
        break;
      case App.Id.btnMenu:
        bloc.current.showDrawer.next(true);
        break;
    }
  };

  const menuSelected = (menu: Menu) => {
    switch (menu.key) {
      case Menu.About.key:
        history.push('/about');
        break;
      case Menu.Humanities.key:
        history.push('/humanities');
        break;
      case Menu.Religion.key:
        history.push('/religion');
        break;
      case Menu.News.key:
        history.push('/news');
        break;
      case Menu.Reports.key:
        history.push('/reports');
        break;
      case Menu.ArtIntro.key:
        history.push('/art/intro');
        break;
      case Menu.ArtArtist.key:
        history.push('/art/artist');
        break;
    }
  };

  /* --- views --- */

  const appbarHeight = smallScreen ? Dimens.appbarS : Dimens.appbar;
  const css = useCss({ screenL, screenM, smallScreen, appbarHeight });

  return (
    <Layout
      alignItems='stretch'
      className={css.root}>
      <AppBar
        itemScope
        itemType='http://schema.org/WPHeader'
        position='fixed'
        elevation={2}
        className={css.appbar}>
        <img
          src={Logo}
          alt='logo'
          className={css.logo}
          onClick={() => btnClicked(App.Id.btnHome)} />
        {screenL ? (
          <Layout
            direction='row'
            alignItems='stretch'>
            {Menu.array.map((menu, index) => {
              return (
                <AppbarMenu
                  key={menu.nameId}
                  menu={menu}
                  subMenu={menu.subList}
                  className={css.menu}
                  onClick={(selectedMenu) => menuSelected(selectedMenu)} />
              );
            })}
          </Layout>
        ) : (
            <Layout direction='row'>
              <IcMenu
                width={Dimens.appbarIcon}
                height={Dimens.appbarIcon}
                className={css.appbarIcMenu}
                onClick={() => btnClicked(App.Id.btnMenu)} />
              <BlocBuilder
                initialValue={bloc.current.showDrawer.getValue()}
                subject={bloc.current.showDrawer}
                builder={snapshot => {
                  return (
                    <AppbarDrawer
                      show={snapshot.data}
                      onClose={() => bloc.current.showDrawer.next(false)}
                      onSelected={(menu) => menuSelected(menu)} />
                  );
                }}
              />
            </Layout>
          )}
      </AppBar>
      <Switch>
        <Route path='/home'>
          <HomePage appbarHeight={appbarHeight} />
        </Route>
        <Route path='/about'>
          <AboutPage appbarHeight={appbarHeight} />
        </Route>
        <Route path='/humanities'>
          <HumanitiesPage appbarHeight={appbarHeight} />
        </Route>
        <Route path='/religion'>
          <ReligionPage appbarHeight={appbarHeight} />
        </Route>
        <Route path='/news'>
          <NewsPage appbarHeight={appbarHeight} />
        </Route>
        <Route path='/reports'>
          <ReportsPage appbarHeight={appbarHeight} />
        </Route>
        <Route path='/art/intro'>
          <ArtPage appbarHeight={appbarHeight} />
        </Route>
        <Route path='/art/artist/detail'>
          <ArtistDetailPage appbarHeight={appbarHeight} />
        </Route>
        <Route path='/art/artist'>
          <ArtistPage appbarHeight={appbarHeight} />
        </Route>
      </Switch>
    </Layout>
  );
};

App.Id = {
  btnHome: 'id_btn_home',
  btnMenu: 'id_btn_menu',
};

type CssProps = {
  screenL: boolean;
  screenM: boolean;
  smallScreen: boolean;
  appbarHeight: string;
};

const useCss = makeStyles<{}, CssProps>({
  root: {
    display: 'flex',
    boxSizing: 'border-box',
    alignItems: 'stretch',
  },
  appbar: {
    display: 'flex',
    boxSizing: 'border-box',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: props => props.appbarHeight,
    backgroundColor: Colors.appbar,
    padding: props => props.screenL ? '0px 7%' : `0px ${Dimens.marginM}`,
  },
  appbarIcMenu: {
    '&:hover': {
      color: Colors.primary,
    },
    '&:active': {
      color: ColorUtils(Colors.primary).darken(0.2).hex(),
    },
    color: Colors.iconB,
    cursor: 'pointer',
  },
  logo: {
    height: props => `calc(${props.appbarHeight} * 0.85)`,
    cursor: 'pointer',
  },
  menu: {
    margin: `${Dimens.marginS} ${Dimens.marginM}`,
  },
});