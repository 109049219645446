import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Layout, PageHeader, PageIntro, Footer } from 'components';

import { Dimens } from 'styles';
import { basicAssetBucketHost } from 'constant';
import intl from 'react-intl-universal';

const ImageBanner = `${basicAssetBucketHost}/humanities/humanities_banner.jpg`;
const Image01 = `${basicAssetBucketHost}/humanities/humanities_01.jpg`
const IntroTitle = '人文';
const IntroDesc = `人文指人類社會的各種文化現象，
文化是人類或者一個民族、一個人群共同具有的符號、價值觀及其規範。
符號是文化的基礎、價值觀是文化的核心、規範包括習慣規範道德規範和法律規範。`

const Detail01Title = '人文委員會';
const Detail01Desc = '主委 金大勝 博士';
const Detail02Title = '曾/現任';
const Detail02Desc = `
法學博士<br>
金大勝<br>
美國西海岸大學前副校長法學数授<br>
國際律師協會IBA會員<br>
美國全國律師協會ABA會員<br>
APEC國際組織OFFICER<br>
考試院資深顧問<br>
中國國民黨中央評議委員
`;

type Props = {
  appbarHeight: string;
};

export const HumanitiesPage: React.FC<Props> = ({
  appbarHeight,
}) => {
  const smallScreen = !useMediaQuery('(min-width: 768px)');
  const css = useCss({ appbarHeight, smallScreen });

  /* --- lifecycle --- */

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  /* --- views --- */

  const detailView = () => {
    if (!smallScreen) {
      return (
        <Layout
          alignSelf='center'
          direction='row'
          width='65%'
          className={css.content}>
          <Layout
            flex={1}>
            <h2 className={css.title}>{Detail01Title}</h2>
            <span className={css.desc} dangerouslySetInnerHTML={{ __html: Detail01Desc }} />
            <h2 className={css.title} style={{ marginTop: `calc(${Dimens.marginL} * 3)` }}>{Detail02Title}</h2>
            <span className={css.desc} dangerouslySetInnerHTML={{ __html: Detail02Desc }} />
          </Layout>
          <Layout flex={1}>
            <LazyLoadImage
              width='100%'
              effect='blur'
              src={Image01}
              alt={`金大勝博士`}
              style={{
                objectFit: 'cover',
                objectPosition: 'center',
              }} />
          </Layout>
        </Layout>
      );
    } else {
      return (
        <Layout
          alignSelf='center'
          width='90%'
          className={css.content}>
          <h2
            className={css.title}
            style={{ alignSelf: 'center' }}>
            {Detail01Title}
          </h2>
          <span
            className={css.desc}
            style={{ alignSelf: 'center' }}
            dangerouslySetInnerHTML={{ __html: Detail01Desc }} />
          <LazyLoadImage
            width='100%'
            effect='blur'
            src={Image01}
            alt={`金大勝博士`}
            style={{
              marginTop: smallScreen ? Dimens.marginL : '0px',
              objectFit: 'cover',
              objectPosition: 'center',
            }} />
          <h2
            className={css.title}
            style={{ marginTop: `calc(${Dimens.marginL} * ${smallScreen ? 1 : 3})` }}>
            {Detail02Title}
          </h2>
          <span className={css.desc} dangerouslySetInnerHTML={{ __html: Detail02Desc }} />
        </Layout>
      );
    }
  }

  return (
    <>
      <main className={css.root}>
        <PageHeader
          imageUrl={ImageBanner}
          imageAlt='humanities_banner'
          title={intl.get('humanities_section_header_title')}
          subTitle={intl.get('humanities_section_header_title_sub')}
        />
        <PageIntro
          title={IntroTitle}
          desc={IntroDesc}
        />
        {detailView()}
      </main>
      <footer>
        <Footer />
      </footer>
    </>
  );
};

type CssProps = {
  appbarHeight: string;
  smallScreen: boolean;
}

const useCss = makeStyles<
  {},
  CssProps,
  'root' | 'content' | 'title' | 'desc'
>({
  root: {
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    alignItems: 'stretch',
    marginTop: props => props.appbarHeight,
    minHeight: props => `calc(100vh - ${props.appbarHeight} - ${Dimens.footer})`,
  },
  content: {
    padding: props => props.smallScreen ? `0px ${Dimens.marginM} ${Dimens.marginL} 0px` : `calc(${Dimens.marginL} * 2) 0px`,
  },
  title: {
    alignSelf: 'flex-start',
    fontFamily: "'Noto Serif TC', serif",
    fontSize: props => props.smallScreen ? Dimens.textL_RWD : Dimens.textL,
    fontWeight: 'bold',
    letterSpacing: '3px',
  },
  desc: {
    alignSelf: 'stretch',
    marginTop: Dimens.marginM,
  },
});
