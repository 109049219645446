import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { useMediaQuery, GridList, GridListTile } from '@material-ui/core';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Layout, PageHeader, Footer } from 'components';

import { Dimens, Colors } from 'styles';
import intl from 'react-intl-universal';
import QueryString from 'query-string';
import { Artists, Paintings } from 'models';
import { basicAssetBucketHost } from 'constant';

const ImageBanner = `${basicAssetBucketHost}/artist/artist_banner.jpg`;

type Props = {
  appbarHeight: string;
};

export const ArtistDetailPage: React.FC<Props> = ({ appbarHeight }) => {
  const history = useHistory();
  const params = QueryString.parse(history.location.search, { parseBooleans: true, parseNumbers: false });
  const uid = (params['uid'] as (string | undefined | null)) ?? '';
  const artist = Artists.find(uid);

  const smallScreen = !useMediaQuery('(min-width: 768px)');
  const css = useCss({ appbarHeight, smallScreen });
  const scss = useSmallCss();

  useEffect(() => {
    if (artist === null) {
      history.replace('/art/artist');
    }
  }, [artist, history]);

  if (artist === null) {
    return (
      <>
        <main />
      </>
    );
  }

  return (
    <>
      <main className={css.root}>
        <PageHeader
          imageUrl={ImageBanner}
          imageAlt='artist_banner'
          title={intl.get('artist_section_header_title')}
          subTitle={intl.get('artist_section_header_title_sub')}
          breadCrumbs={['首頁', '藝術家', artist.name]}
        />
        <Layout className={css.content} alignItems='stretch'>
          {/* --- Profile --- */}
          <Layout direction='row'>
            <Layout className={css.avatar}>
              <LazyLoadImage
                itemProp='image'
                width='100%'
                height='100%'
                effect='blur'
                src={artist.avatar}
                alt='artist_avatar'
                style={{
                  objectFit: 'cover',
                  objectPosition: 'center',
                }} />
            </Layout>
            <Layout className={css.profileCol}>
              <span className={css.profileName}>{artist.name}</span>
              <span className={css.profileCountry}>{artist.country}</span>
            </Layout>
          </Layout>
          {/* --- 經歷 --- */}
          <Layout
            direction={smallScreen ? 'column' : 'row'}
            style={{ marginTop: `calc(${Dimens.marginL} + ${Dimens.marginM})` }}>
            {/* --- Titles --- */}
            <Layout flex={1}>
              <span className={css.titleHeader}>{intl.get('artist_detail_title_header')}</span>
              {artist.titles.map((title, index) => {
                return (
                  <span
                    className={css.titleItem}
                    style={{ marginTop: index === 0 ? Dimens.marginM : '0px' }}>
                    {title}
                  </span>
                );
              })}
            </Layout>
            {/* --- Works --- */}
            <Layout flex={1}>
              <span className={css.workHeader}>{intl.get('artist_detail_work_header')}</span>
              {artist.works.map((work, index) => {
                return (
                  <span
                    className={css.workItem}
                    style={{ marginTop: index === 0 ? Dimens.marginM : '0px' }}>
                    {work}
                  </span>
                );
              })}
            </Layout>
          </Layout>
          <div className={css.divider} />
          <Layout style={{ marginTop: Dimens.marginL, }}>
            <span className={css.artsHeader}>{intl.get('artist_detail_arts_header')}</span>
            <GridList
              className={css.paintingList}
              cellHeight='auto'
              cols={smallScreen ? 1 : 3}>
              {Paintings.巫永堅.map((painting, index) => {
                const height = smallScreen ? '90vw' : `calc((70vw - 40px) / 3)`;
                const paddingLeft = (index % 3 === 0 || smallScreen) ? '0px' : '10px';
                const paddingRight = (index % 3 === 2 || smallScreen) ? '0px' : '10px';
                return (
                  <GridListTile
                    key={`artworks_index_${index}`}
                    style={{
                      padding: `10px ${paddingRight} 10px ${paddingLeft}`,
                    }}>
                    <Layout
                      itemScope itemType='http://schema.org/Painting'
                      height={height}
                      className={css.paintingRoot}>
                      <LazyLoadImage
                        itemProp='image'
                        width='100%'
                        height='100%'
                        effect='blur'
                        src={painting.url}
                        alt={`painting_${artist.name}_${painting.name}`}
                        style={{
                          objectFit: 'cover',
                          objectPosition: 'center',
                        }} />
                      <Layout
                        justifyContent='flex-end'
                        className={css.paintingCover}>
                        <span
                          itemProp='name'
                          className={smallScreen ? scss.paintingName : css.paintingName}>
                          {painting.name}
                        </span>
                      </Layout>
                    </Layout>
                  </GridListTile>
                );
              })}
            </GridList>
          </Layout>
        </Layout>
      </main >
      <footer>
        <Footer />
      </footer>
    </>
  );
}

type CssProps = {
  appbarHeight: string;
  smallScreen: boolean;
};

const useCss = makeStyles<
  {},
  CssProps,
  'root' | 'content' | 'avatar' |
  'profileCol' | 'profileName' | 'profileCountry' |
  'titleItem' | 'titleHeader' | 'workHeader' | 'workItem' |
  'divider' |
  'artsHeader' | 'paintingList' | 'paintingRoot' | 'paintingCover' | 'paintingName'
>({
  root: {
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    alignItems: 'stretch',
    marginTop: props => props.appbarHeight,
    minHeight: props => `calc(100vh - ${props.appbarHeight} - ${Dimens.footer})`,
  },
  content: {
    alignSelf: 'center',
    width: (props) => props.smallScreen ? '90vw' : '70vw',
    padding: props => props.smallScreen ? `${Dimens.marginL} 0px ${Dimens.marginL} 0px` : `calc(${Dimens.marginL} * 2) 0px calc(${Dimens.marginL} * 2)`,
  },
  avatar: {
    width: (props) => `calc(${props.smallScreen ? '90vw' : '70vw'} / 4)`,
    height: (props) => `calc(${props.smallScreen ? '90vw' : '70vw'} / 4)`,
    borderStyle: 'solid',
    borderRadius: Dimens.corner,
    borderWidth: '0px',
    overflow: 'hidden',
  },
  profileCol: {
    marginLeft: (props) => props.smallScreen ? Dimens.marginM : Dimens.marginL,
  },
  profileName: {
    fontSize: Dimens.textL_RWD,
    fontWeight: 'bold',
    color: Colors.textBD,
  },
  profileCountry: {
    fontSize: Dimens.textM,
    color: Colors.textB,
    marginTop: Dimens.marginXS,
  },
  titleHeader: {
    fontSize: Dimens.textL_RWD,
    fontWeight: 'bold',
    color: Colors.textBD,
  },
  titleItem: {
    fontSize: Dimens.textM,
    color: Colors.textB,
  },
  workHeader: {
    fontSize: Dimens.textL_RWD,
    fontWeight: 'bold',
    color: Colors.textBD,
    marginTop: (props) => props.smallScreen ? Dimens.marginM : '0px'
  },
  workItem: {
    fontSize: Dimens.textM,
    color: Colors.textB,
  },
  divider: {
    height: Dimens.divider,
    backgroundColor: Colors.dividerB,
    marginTop: Dimens.marginM,
    marginBottom: Dimens.marginM,
  },
  artsHeader: {
    fontSize: Dimens.textL_RWD,
    fontWeight: 'bold',
    color: Colors.textBD,
  },
  paintingList: {
    marginTop: `${Dimens.marginM} !important`,
  },
  paintingRoot: {
    position: 'relative',
  },
  paintingCover: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '100%',
    backgroundImage: 'linear-gradient(transparent, transparent, #000000c0)',
    padding: `${Dimens.marginM} ${Dimens.marginM}`,
  },
  paintingName: {
    fontSize: '1.1rem',
    fontWeight: 500,
    color: Colors.textW,
    marginTop: Dimens.marginXS,
    marginBottom: Dimens.marginXS,
  },
});

const useSmallCss = makeStyles({
  paintingName: {
    fontSize: Dimens.textM,
    fontWeight: 500,
    color: Colors.textW,
    marginTop: Dimens.marginXS,
  },
});