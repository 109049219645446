import React, { useState } from 'react';
import { makeStyles, ThemeProvider } from '@material-ui/styles';
import { Checkbox as MaterialCheckbox, createMuiTheme } from '@material-ui/core';
import { Layout } from 'component/common/layout';
import { BtnText } from 'component/common/btn_text';

import { Dimens, Colors } from 'styles';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  checked?: boolean;
  color?: string;
  txt?: string;
  txtColor?: string;
  fontSize?: string;
  onChecked?: (checked: boolean) => void;
};

export const Checkbox: React.FC<Props> = ({
  className,
  style,
  checked = false,
  color = '#000000',
  txt = '',
  txtColor = Colors.textB,
  fontSize = Dimens.textM,
  onChecked,
}) => {
  const css = useCss();
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: color,
      },
      secondary: {
        main: color,
      },
    },
  });

  const [enable, setEnable] = useState<boolean>(checked);

  return (
    <ThemeProvider theme={theme}>
      <Layout
        direction='row'
        alignItems='center'
        className={`${className}`}
        style={style}>
        <MaterialCheckbox checked={enable} size='small' onChange={(event) => {
          setEnable(event.target.checked);
          if (onChecked) {
            onChecked(event.target.checked);
          }
        }} />
        <BtnText
          txt={txt}
          color={txtColor}
          fontSize={fontSize}
          className={css.txt}
          onClick={() => setEnable(prev => !prev)} />
      </Layout>
    </ThemeProvider>
  );
};

const useCss = makeStyles({
  txt: {
    paddingBottom: '2px',
  },
});
