import React, { useState, useEffect, Fragment } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Drawer, Collapse, List, ListItem, ListItemText } from '@material-ui/core';
import { Layout } from 'components';

import { Menu } from 'models';

import { Dimens } from 'styles';

import IcExpandMore from '@material-ui/icons/ExpandMoreRounded';
import IcExpandLess from '@material-ui/icons/ExpandLessRounded';

type Props = {
  show?: boolean;
  onClose?: () => void;
  onSelected?: (menu: Menu) => void;
};

export const AppbarDrawer: React.FC<Props> = ({
  show = false,
  onClose,
  onSelected,
}) => {
  const css = useCss();

  const [subMenuOpen, setSubMenuOpen] = useState<string | null>(null);

  /* --- lifecycle --- */

  useEffect(() => {
    if (!show) {
      setSubMenuOpen(null);
    }
  }, [show]);

  /* --- functions --- */

  const itemClicked = (menu: Menu) => {
    if (menu.more) {
      setSubMenuOpen(subMenuOpen === menu.key ? null : menu.key);
    } else {
      if (onSelected) onSelected(menu);
      if (onClose) onClose();
    }
  };

  /* --- views --- */

  const subMenuView = (key: string, subMenus: Menu[]) => {
    const open = subMenuOpen === key;
    return (
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {subMenus.map((item) => {
            return (
              <ListItem
                key={`sub_menu_${item.key}`}
                button
                className={css.subMenu}
                onClick={() => itemClicked(item)}
              >
                <ListItemText primary={item.name} />
              </ListItem>
            );
          })}
        </List>
      </Collapse>
    );
  }

  return (
    <Drawer
      open={show}
      anchor='right'
      onClose={() => {
        if (onClose) onClose();
      }}>
      <Layout className={css.root}>
        <List className={css.itemRoot}>
          {Menu.array.map((menu) => (
            <Fragment key={menu.key}>
              <ListItem
                button
                onClick={() => itemClicked(menu)}>
                <ListItemText primary={menu.name} />
                {!menu.more ? null : (subMenuOpen === menu.key ? <IcExpandLess /> : <IcExpandMore />)}
              </ListItem>
              {!menu.more || !menu.subList ? null : subMenuView(menu.key, menu.subList)}
            </Fragment>
          ))}
        </List>
      </Layout>
    </Drawer>
  );
}

const useCss = makeStyles({
  root: {
    width: '35vw',
    minWidth: Dimens.drawerW,
  },
  itemRoot: {
    width: '100%',
  },
  subMenu: {
    paddingLeft: Dimens.marginL,
  }
});