export const zh_tw = {
  company_name: '中華宗教藝術人文協會',
  company_name_en: 'China Religious Art and Humanities',
  company_address: '台灣台東市新成街112號',
  company_mobile_phone: '0955-290-459',
  company_tel_phone: '089-235383',
  company_email: 'janko@gmail.com',
  company_website: 'www.ch-rah.com',

  /* General */
  taiwan: '台灣',
  china: '中國',
  send: '送出',
  search: '搜尋',

  /* Header Bar Menu */
  menu_about: '關於CRAH',
  menu_news: '最新消息',
  menu_religion: '宗教',
  menu_art: '藝術',
  menu_art_intro: '簡介',
  menu_art_artist: '藝術家',
  menu_art_artworks: '藝術品',
  menu_humanities: '人文',
  menu_reports: '專題報導',
  menu_contact: '聯絡我們',

  /* --- footer --- */
  footer_about: '關於人文',
  footer_privacy: '隱私權聲明',
  footer_service: '服務條款',
  footer_qa: '常見問題',
  footer_contact: '聯絡我們',
  footer_copyright: '2020 Copyright © www.ch-rah.com.tw',
  footer_reserved: 'All Rights Reserved.',

  /* --- home page --- */
  home_section_artworks_title: 'ARTWORKS',
  home_section_artworks_title_sub: '藝術品',
  home_section_about_title: '中華宗教藝術人文協會',
  home_section_about_desc: '對於藝術人文，我們擁有更遠的展望。CRAH並不侷限自己僅是藝術品，我們積極推廣人文藝術在生活中能展現，且定期提供精選展覽文章及收藏訊息，希望瀏覽者能不受限，身歷其境般參與國內外藝術盛事；不但能相互交流作品，也能汲取各個宗教的人文交流與最新資訊，提供觀眾欣賞當代藝術精品不一樣的視界。',
  home_section_about_more: '看更多資訊 >>>',
  home_section_contact_title: 'CONTACT',
  home_section_contact_title_sub: '聯絡我們',
  home_section_contact_form_name_hint: '姓名*',
  home_section_contact_form_phone_hint: '電話*',
  home_section_contact_form_email_hint: 'Email*',
  home_section_contact_form_subject_hint: '訊息主旨*',
  home_section_contact_form_content_hint: '訊息內容*',

  /* --- about page --- */
  about_section_header_title: 'ABOUT',
  about_section_header_title_sub: '關於我們',

  /* --- humanities page --- */
  humanities_section_header_title: 'HUMANITIES',
  humanities_section_header_title_sub: '人文',

  /* --- religion page --- */
  religion_section_header_title: 'RELIGION',
  religion_section_header_title_sub: '宗教',

  /* --- news page --- */
  news_section_header_title: 'NEWS',
  news_section_header_title_sub: '最新消息',

  /* --- reports page --- */
  reports_section_header_title: 'REPORTS',
  reports_section_header_title_sub: '專題報導',

  /* --- art page --- */
  art_section_header_title: 'ARTS',
  art_section_header_title_sub: '藝術',

  /* --- artist page --- */
  artist_section_header_title: 'ARTIST',
  artist_section_header_title_sub: '藝術家',

  /* --- artist detail page --- */
  artist_detail_title_header: '曾/現任',
  artist_detail_work_header: '設計作品',
  artist_detail_arts_header: '所有作品',
};