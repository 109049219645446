import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { useMediaQuery, GridList, GridListTile } from '@material-ui/core';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Layout, PageHeader, Footer, Select, TextField } from 'components';

import { Dimens, Colors } from 'styles';
import intl from 'react-intl-universal';

import { Artist, Artists } from 'models';

import { SearchRounded } from '@material-ui/icons';
import { basicAssetBucketHost } from 'constant';

const ImageBanner = `${basicAssetBucketHost}/artist/artist_banner.jpg`;

type Props = {
  appbarHeight: string;
};

export const ArtistPage: React.FC<Props> = ({ appbarHeight }) => {
const history = useHistory();

  const smallScreen = !useMediaQuery('(min-width: 768px)');
  const css = useCss({ appbarHeight, smallScreen });
  const scss = useSmallCss();

  const onArtistClicked = (artist: Artist) => {
    console.log('artist clicked:', artist);
    if (artist.uid === '0') {
      history.push({
        pathname: '/art/artist/detail',
        search: `?uid=${artist.uid}`,
      });
    }
  };

  return (
    <>
      <main className={css.root}>
        <PageHeader
          imageUrl={ImageBanner}
          imageAlt='artist_banner'
          title={intl.get('artist_section_header_title')}
          subTitle={intl.get('artist_section_header_title_sub')}
        />
        <Layout className={css.content} alignItems='stretch'>
          {/* 搜尋條件列 */}
          <Layout direction='row' justifyContent='center'>
            <Select
              value='國家'
              items={[
                { id: 'tw', value: '台灣' },
                { id: 'cn', value: '中國' }
              ]} />
            <TextField
              className={css.search}
              width='200px'
              height={Dimens.btn}
              border
              borderColor={Colors.selectBorder}
              borderRadius='0px'
              hint={intl.get('search')}
              icon={SearchRounded} />
          </Layout>
          <GridList
            cellHeight={300}
            cols={3}
            className={css.list}>
            {Artists.values.map((artist, index) => {
              let country = '';
              switch (artist.country) {
                case 'tw':
                  country = intl.get('taiwan');
                  break;
                case 'cn':
                  country = intl.get('china');
                  break;
              }
              return (
                <GridListTile
                  key={`artist_${artist.name}`}
                  style={{
                    padding: '10px',
                    cursor: 'pointer',
                  }}
                  onClick={() => onArtistClicked(artist)}>
                  <Layout
                    itemScope itemType='http://schema.org/Painting'
                    height='300px'
                    className={css.itemRoot}>
                    <LazyLoadImage
                      itemProp='image'
                      width='100%'
                      height='100%'
                      effect='blur'
                      src={artist.image}
                      alt={`artist_profile_${artist.name}`}
                      style={{
                        objectFit: 'cover',
                        objectPosition: 'center',
                      }} />
                    <Layout
                      justifyContent='flex-end'
                      className={css.itemCover}>
                      <span
                        itemProp='country'
                        className={smallScreen ? scss.itemAuthor : css.itemAuthor}>
                        {country}
                      </span>
                      <span
                        itemProp='name'
                        className={smallScreen ? scss.itemName : css.itemName}>
                        {artist.name}
                      </span>
                    </Layout>
                  </Layout>
                </GridListTile>
              )
            })}
          </GridList>
        </Layout>
      </main>
      <footer>
        <Footer />
      </footer>
    </>
  );
}

type CssProps = {
  appbarHeight: string;
  smallScreen: boolean;
};

const useCss = makeStyles<
  {},
  CssProps,
  'root' | 'content' | 'search' | 'list' | 'itemRoot' | 'itemCover' | 'itemAuthor' | 'itemName'
>({
  root: {
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    alignItems: 'stretch',
    marginTop: props => props.appbarHeight,
    minHeight: props => `calc(100vh - ${props.appbarHeight} - ${Dimens.footer})`,
  },
  content: {
    padding: props => props.smallScreen ? `0px ${Dimens.marginM} ${Dimens.marginL} 0px` : `${Dimens.marginL} 0px calc(${Dimens.marginL} * 2)`,
  },
  search: {
    marginLeft: Dimens.marginM,
  },
  list: {
    alignSelf: 'center',
    width: (props) => props.smallScreen ? '90vw' : '70vw',
    marginTop: `calc(${Dimens.marginL} * 2) !important`,
  },
  itemRoot: {
    position: 'relative',
  },
  itemCover: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '100%',
    backgroundImage: 'linear-gradient(transparent, transparent, #000000c0)',
    padding: `${Dimens.marginM} ${Dimens.marginM}`,
  },
  itemAuthor: {
    fontSize: Dimens.textM,
    color: '#ffffffb0',
  },
  itemName: {
    fontSize: '1.1rem',
    fontWeight: 500,
    color: Colors.textW,
    marginTop: Dimens.marginXS,
    marginBottom: Dimens.marginXS,
  },
});

const useSmallCss = makeStyles({
  item: {
    position: 'relative',
  },
  itemAuthor: {
    fontSize: '1.7rem',
    color: Colors.textW,
  },
  itemName: {
    fontSize: Dimens.textM,
    fontWeight: 500,
    color: Colors.textW,
    marginTop: Dimens.marginXS,
  },
});
