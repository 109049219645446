import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { Dimens, Colors } from 'styles';
import { basicAssetBucketHost } from 'constant';

export const News00Page: React.FC = () => {
  const less1000 = !useMediaQuery('(min-width: 1000px)');
  const css = useCss();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className={css.root}>
      <h2 className={css.title}>中華宗教藝術人文協會活動花絮</h2>
      <span className={css.date}>108.05.15</span>
      <div className={css.divider} />
      <div className={css.content}>
        <span>全國性社會團體申請<br />發起人：56人<br />發起人名單<br /></span>
        <LazyLoadImage
          width={less1000 ? '100%' : '80%'}
          effect='blur'
          src={`${basicAssetBucketHost}/news/photo_1080515.jpg`}
          alt='photo_1080515_01.jpg'
          style={{
            marginTop: '40px',
          }}
        />
      </div>
    </section>
  );
};

const useCss = makeStyles({
  root: {
    padding: '40px 15vw 100px',
  },
  title: {
    alignSelf: 'flex-start',
    fontFamily: "'Noto Serif TC', serif",
    fontSize: Dimens.newsTextTitle,
    fontWeight: 'bold',
  },
  date: {
    marginTop: Dimens.marginS,
    marginBottom: Dimens.marginS,
    fontSize: Dimens.textM,
  },
  divider: {
    height: Dimens.divider,
    backgroundColor: Colors.divider,
  },
  content: {
    padding: '40px 0px'
  }
});