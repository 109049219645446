import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import 'asset/css/slick_theme.css';
import { basicAssetBucketHost } from 'constant';

const Banners = [
  {
    url: `${basicAssetBucketHost}/home/banner/img_home_banner_01.jpg`,
  },
  {
    url: `${basicAssetBucketHost}/home/banner/img_home_banner_02.jpg`,
  },
];

type Props = {};

export const HomeBanner: React.FC<Props> = () => {
  const css = useCss();

  return (
    <Slider
      dots
      autoplay
      autoplaySpeed={6000}
      pauseOnHover={false}
      arrows={false}>
      {Banners.map((item, index) => {
        return (
          <div
            key={`home_banner_${index}`}
            className={css.item}>
            <img
              src={item.url}
              alt={`home_banner_${index}`}
              className={css.image} />
          </div>
        );
      })}
    </Slider>
  );
};

const useCss = makeStyles({
  root: {
    display: 'flex',
    position: 'relative',
    paddingBottom: `${820 / 1920 * 100}%`,
    backgroundColor: '#d0d0d0',
  },
  item: {
  },
  image: {
    width: '100%',
  }
});