import React, { useState } from 'react';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { Paper, ButtonBase, Popper, ClickAwayListener } from '@material-ui/core';
import { Layout } from 'components';

import ColorUtils from 'color';
import { Dimens, Colors } from 'styles';

import { ExpandMoreRounded } from '@material-ui/icons';

export type SelectItem = {
  id: string;
  value: string;
};

type Props = {
  className?: string;
  style?: React.CSSProperties;
  width?: string;
  height?: string;
  borderRadius?: string;
  value: string;
  items: SelectItem[];
  onClick?: (id: string) => void;
};

export const Select: React.FC<Props> = ({
  className,
  style,
  width,
  height,
  borderRadius,
  value,
  items,
  onClick,
}) => {
  const css = useCss({ width, height, borderRadius });
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const open = Boolean(anchorEl);

  return (
    <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
      <Layout
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        className={clsx(css.root, className)}
        style={style}
        // onHover={(hover, event) => {
        //   setAnchorEl(hover ? event.currentTarget : null);
        // }}
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}>
        <AButton
          className={css.btn}>
          {value}
        </AButton>
        <ExpandMoreRounded className={css.icon} />
        <Popper
          open={open}
          anchorEl={anchorEl}
          className={css.dropdownRoot}>
          <Paper
            elevation={4}
            className={css.dropdown}>
            {items.map((item) => {
              return (
                <ButtonBase
                  key={`select_item_${item.id}`}
                  className={css.dropdownBtn}
                  onClick={() => {
                    if (onClick) {
                      onClick(item.id);
                    }
                  }}>
                  <span className={css.dropdownBtnText}>
                    {item.value}
                  </span>
                </ButtonBase>
              );
            })}
          </Paper>
        </Popper>
      </Layout>
    </ClickAwayListener>
  );
};

type CssProps = {
  height?: string,
  width?: string;
  borderRadius?: string;
}

const useCss = makeStyles<
  {},
  CssProps,
  'root' | 'btn' | 'icon' | 'dropdownRoot' | 'dropdown' | 'dropdownBtn' | 'dropdownBtnText'
>({
  root: {
    position: 'relative',
    '&:active': {
      '& $icon': {
        color: ColorUtils(Colors.primary).darken(0.2).hex(),
      },
      '& $btn': {
        color: ColorUtils(Colors.primary).darken(0.2).hex(),
      },
    },
    '&:hover': {
      '& $icon': {
        color: Colors.primary,
      },
      '& $btn': {
        color: Colors.primary,
      },
    },
    height: (props) => props.height ?? Dimens.btn,
    width: (props) => props.width ?? '200px',
    padding: `0px 10px`,
    outline: 'none',
    cursor: 'pointer',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: Colors.selectBorder,
    borderRadius: (props) => props.borderRadius,
  },
  btn: {},
  icon: {
    color: Colors.iconB,
    width: Dimens.appbarMenuMoreIcon,
    height: Dimens.appbarMenuMoreIcon,
  },
  dropdownRoot: {
    zIndex: 1200,
  },
  dropdown: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    width: (props) => props.width ?? '200px',
    backgroundColor: '#ffffff',
  },
  dropdownBtn: {
    '&:hover': {
      color: Colors.primary,
    },
    flexDirection: 'column',
    padding: '0px 10px',
    height: Dimens.appbarMenuSub,
    fontSize: Dimens.textS,
    color: Colors.textB,
  },
  dropdownBtnText: {
    alignSelf: 'flex-start',
  },
});

type ButtonProps = {};

const AButton = styled.a<ButtonProps>`
    /* :active {
      color: ${ColorUtils(Colors.primary).darken(0.2).hex()} !important;
    }; */
    outline: none;
    border: none;
    font-size: ${Dimens.textM};
    color: ${Colors.textB};
    background-color: transparent;
    padding: 0px;
    -webkit-tap-highlight-color: transparent;
    user-select: none;
`;
