import React from 'react';
import styled from 'styled-components';

import ColorUtils from 'color';

import { Dimens, Colors } from 'styles';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  elementType?: 'input' | 'a';
  width?: string;
  height?: string;
  color?: string;
  fontSize?: string;
  txt: string;
  onClick?: () => void;
}

export const BtnText: React.FC<Props> = ({
  className,
  style,
  elementType = 'input',
  width = 'auto',
  height = 'auto',
  color = Colors.textB,
  fontSize = Dimens.textM,
  txt = '',
  onClick,
}) => {

  switch (elementType) {
    case 'a':
      return (
        <AButton
          className={className}
          style={style}
          type='button'
          width={width}
          height={height}
          btn_color={color}
          font_size={fontSize}
          onClick={() => {
            if (onClick) {
              onClick();
            }
          }}>
          {txt}
        </AButton>
      );
    default:
      return (
        <InputButton
          className={className}
          style={style}
          type='button'
          value={txt}
          width={width}
          height={height}
          btn_color={color}
          font_size={fontSize}
          onClick={() => {
            if (onClick) {
              onClick();
            }
          }} />
      );
  }
};

type ButtonProps = {
  width: string;
  height: string;
  btn_color: string;
  font_size: string;
};

const InputButton = styled.input<ButtonProps>`
    :hover {
      color: ${props => ColorUtils(props.btn_color).darken(0.3).hex()};
    };
    :active {
      color: ${props => ColorUtils(props.btn_color).darken(0.3).darken(0.1).hex()};
    };
    width: ${props => props.width};
    height: ${props => props.height};
    outline: none;
    border: none;
    font-size: ${props => props.font_size};
    color: ${props => props.btn_color};
    background-color: transparent;
    cursor: pointer;
    padding: 0px;
    -webkit-tap-highlight-color: transparent;
    user-select: none;
`;

const AButton = styled.a<ButtonProps>`
    :hover {
      color: ${props => ColorUtils(props.btn_color).darken(0.3).hex()};
    };
    :active {
      color: ${props => ColorUtils(props.btn_color).darken(0.3).darken(0.1).hex()};
    };
    outline: none;
    border: none;
    font-size: ${props => props.font_size};
    color: ${props => props.btn_color};
    background-color: transparent;
    padding: 0px;
    -webkit-tap-highlight-color: transparent;
    user-select: none;
`;