export const Colors = {
  primary: '#e57330',
  accent: '#4b4c4d',
  disable: '#c0c0c0',
  red: '#B2292C',

  textB: '#3A393B',
  textBD: '#211915',
  textW: '#ffffff',

  dividerB: '#d0d0d0',
  divider: '#e0e0e0',
  dividerL: '#f0f0f0',

  appbar: '#ffffff',
  page: '#ffffff',
  footer: '#4b4c4d',

  iconB: '#48494a',

  homeSocialIcon: '#4c4c4c',
  homeContactBox: '#575758',

  selectBorder: '#A5A5A5',
};