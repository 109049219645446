import React, { HTMLProps } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { useMediaQuery, Paper } from '@material-ui/core';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Layout, Btn } from 'components';

import { Report } from 'models';

import { Dimens, Colors } from 'styles';

type ScreenSize = 'small' | 'medium' | 'large';

const getScreenSize = (less768: boolean, less1000: boolean): ScreenSize => {
  if (less768) return 'small';
  if (less1000) return 'medium';
  return 'large';
}

interface Props extends HTMLProps<HTMLElement> {
  report: Report;
}

export const ReportsCard: React.FC<Props> = ({
  style,
  className,
  report,
}) => {
  const less768 = !useMediaQuery('(min-width: 768px)');
  const less1000 = !useMediaQuery('(min-width: 1000px)');
  const screenSize = getScreenSize(less768, less1000);

  const css = useCss({ screenSize });

  const view = () => {
    return (
      <>
        <Layout
          flex={screenSize === 'small' ? undefined : 5.3}>
          <LazyLoadImage
            width='100%'
            height={screenSize === 'small' ? undefined : '100%'}
            effect='blur'
            src={report.imageUrl}
            alt={report.title}
            style={{
              objectFit: 'cover',
              objectPosition: 'center',
            }} />
        </Layout>
        <Layout
          flex={screenSize === 'small' ? undefined : 4.7}
          direction='column'
          alignItems='stretch'
          className={css.body}>
          <Layout direction='row'>
            <div className={css.titleIndicator} />
            <h2 className={css.title}>{report.title}</h2>
          </Layout>
          <span className={clsx(css.content, css.line4)}>
            {report.content}
          </span>
          <span className={css.date}>
            {`報導時間 ${report.date}`}
          </span>
          <Btn
            color={Colors.red}
            round='none'
            padding={`0px ${Dimens.marginL}`}
            txt='詳細看更多'
            className={css.btnMore} />
        </Layout>
      </>
    );
  };

  return (
    <Paper
      elevation={4}
      style={style}
      className={clsx(css.root, className)}>
      {view()}
    </Paper>
  );
};

type CssProps = {
  screenSize: 'small' | 'medium' | 'large';
};

const useCss = makeStyles<
  {},
  CssProps,
  'root' | 'body' |
  'titleIndicator' | 'title' |
  'content' | 'line2' | 'line4' |
  'date' | 'btnMore'
>({
  root: {
    display: 'flex',
    flexDirection: props => props.screenSize === 'small' ? 'column' : 'row',
    boxSizing: 'border-box',
    alignItems: 'stretch',
    borderRadius: '0px',
  },
  body: {
    padding: props => `${Dimens.marginM} ${props.screenSize === 'small' ? Dimens.marginM : Dimens.marginL} ${Dimens.marginM} ${Dimens.marginM}`,
  },
  titleIndicator: {
    alignSelf: 'stretch',
    width: Dimens.reportsCardIndicator,
    backgroundColor: Colors.red,
  },
  title: {
    display: '-webkit-box',
    boxOrient: 'vertical',
    fontFamily: "'Noto Serif TC', serif",
    fontSize: props => props.screenSize === 'small' ? Dimens.reportsCardTitle_RWD : Dimens.reportsCardTitle,
    fontWeight: 'bold',
    letterSpacing: '3px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineClamp: 1,
    marginLeft: props => props.screenSize === 'small' ? Dimens.marginS : Dimens.marginM,
  },
  content: {
    minHeight: props => props.screenSize === 'small' ? '50px' : '100px',
    display: '-webkit-box',
    boxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginTop: Dimens.marginM,
  },
  line2: {
    lineClamp: 2,
  },
  line4: {
    lineClamp: 4,
  },
  date: {
    fontWeight: 500,
    marginTop: Dimens.marginS,
  },
  btnMore: {
    alignSelf: 'flex-end',
    marginTop: props => props.screenSize === 'small' ? Dimens.marginM : Dimens.marginL,
  },
});